import React, { useState, useRef } from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            ©Copyright 2018 Admin Theme by Ray
        </footer>
    );
};

export default Footer;
