import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/selection/active-line';

import * as download from 'downloadjs';

// import { query_by_sql, download_query_result } from '../../js/review';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

const Review = () => {
    const history = useHistory();
    const sqlEditorRef = useRef();

    function build_record_table(result) {
        if(!result || !result.length){
            $('#record_position_div').append('<h3><span style="text-align: center;display:block;">No Data</span></h3>');
            return;
        }
        const columns = Object.keys(result[0]);
        let records_html = `<h3><span style="text-align: center;display:block;">共查询到${result.length}条数据</span></h3>`;
        let odd_or_even = 'odd';
        records_html = records_html.concat(`<div style="overflow:scroll;">
                    <table id = 'reviewRecordTable' class="display nowrap table table-hover table-striped table-bordered dataTable" role="grid">
                `);
        records_html = records_html.concat('<thead><tr role="row">');
        for(const column of columns){
            records_html = records_html.concat(`<th rowspan="1" colspan="1" style="font-size: small; text-align: left">${column}</th>`);
        }
        records_html = records_html.concat('</tr></thead>');
        records_html = records_html.concat('<tbody>');
        for( const record of result ) {
            odd_or_even = odd_or_even === 'odd' ? 'even':'odd';
            records_html = records_html.concat(`<tr role="row" class="'${odd_or_even}'" style="height: 50px">`);
            for(const column of columns){
                const tmp = record[column] === undefined ? '':String(record[column]);
                const tmp_text = tmp.length > 40? `${tmp.substring(0, 40)}...`:tmp;
                if(tmp_text !== tmp) {
                    const uuid = generate_UUID();
                    records_html = records_html.concat(`<td style="width: 10px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                        <span id = '${uuid}' class='popover-show'>${tmp_text}</span>
                        <span id = '${uuid}_full_value' hidden="hidden">${tmp}</span>
                   </td>`);
                } else {
                    records_html = records_html.concat(`<td style="width: 10px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">${tmp_text}</td>`);
                }
            }
            records_html = records_html.concat('</tr>');
        }
        records_html = records_html.concat('</tbody>');
        records_html = records_html.concat(`</table></div>`);
        $('#record_position_div').append(records_html);
    }

    async function query_by_sql(sqlEditorRef) {
        const sql_area = sqlEditorRef.current.editor;
        $("#query_btn").attr("disabled", true);
        delete window.sql_query_result;
        const sql = sql_area.getValue();
        $('#record_position_div').html('');
        try {
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            await $.ajax({
                url: '/api/v1/review/query/sql?username=' + username + '&menuId=' + menuId,
                type: 'post',
                timeout : 300000,
                data: {
                    sql,
                },
                success: function (result) {
                    if (result.err) {
                        $('#record_position_div')
                            .append(`<h4><span style="text-align: center;display:block;">${result.err}</span></h4>`);
                        return;
                    }
                    window.sql_query_result = result.data;
                    build_record_table(result.data);
                    add_popover();
                },
                error: function (err) {
                    $('#record_position_div').append(`<h3><span style="text-align: center;display:block;">ERROR:${err && err.message}</span></h3>`);
                }
            })
        }catch (e) {

        }
        $("#query_btn").attr("disabled", false);
    }

    function download_query_result() {
        if(!window.sql_query_result){
            alert('查询结果为空');
            return;
        }
        const data = JSON.stringify(window.sql_query_result, null, 4);
        download(data, 'query_result.json', 'text/plain');
    }

    function add_popover() {
        const spans = $('.popover-show');
        for(let i = 0 ; i< spans.length ;i++){
            const span = spans[i];
            if(span.getAttribute('popover_added')){
                continue;
            }
            const span_id = span.getAttribute('id');
            const full_value_id = `${span_id}_full_value`;
            const content = $(`#${full_value_id}`).text();
            const obj = $(`#${span_id}`);
            obj.popover({
                trigger:'manual',//manual 触发方式
                placement : 'bottom',
                html: true,
                content: `${content}`,
                animation: false
            }) .on("mouseenter", function () {
                const _this = this;
                $(this).popover("show");
                $(this).siblings(".popover").on("mouseleave", function () {
                    $(_this).popover('hide');
                });
            }).on("mouseleave", function () {
                const _this = this;
                setTimeout(function () {
                    if (!$(".popover:hover").length) {
                        $(_this).popover("hide")
                    }
                }, 100);
            });
            span.setAttribute('popover_added', 'yes');
        }
    }

    function generate_UUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }

    useEffect(() => {
        const role = localStorage.getItem("role");
        if (role !== 'superAdmin') {
            history.push('/page/setting.html');
        }

        const sql_area = sqlEditorRef.current.editor;
        sql_area.setSize(600, 260);
        sql_area.setValue(
            `-- 查询某平台版本审核sql
select * from ip_review
   where remark = 'InReview'
     and platform= '#{platform}'
     and app_version= '#{app_version}'
   order by time desc
`
        );
    }, []);

    return (
        <div className="page-wrapper" style={{overflowAnchor: 'none', marginTop: '20px'}}>
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">审核</h3>
                </div>
                <div className="col-md-7 align-self-center">
                </div>
            </div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card card-outline-info">
                        <div className="card-header">
                            <h4 className="m-b-0 text-white">审核记录查询</h4>
                        </div>
                        <br/>
                        <br/>
                        <div className="card-body">
                            <div id="sql_div">
                                <CodeMirror
                                    id="sql_editor"
                                    name="editor"
                                    className="config_content"
                                    ref={sqlEditorRef}
                                    value=''
                                    options={{
                                        mode: 'text/x-mysql',
                                        lineNumbers: true,
                                        indentUnit: 4
                                    }}
                                    onChange={(editor, data, value) => {
                                        //
                                    }}
                                />
                            </div>
                            <br/>
                            <br/>
                            <div className="row justify-content-center">
                                <div>
                                    <button id="query_btn" onClick={query_by_sql.bind(this, sqlEditorRef)} className="btn btn-info"
                                            style={{width: 200, height: 40}}>查询
                                    </button>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div>
                                    <button id="save_btn" onClick={download_query_result.bind(this)} className="btn btn-info"
                                            style={{width: 200, height: 40}}>下载结果
                                    </button>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div id="record_position_div"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Review;
