import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Script from 'react-load-script';

function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; //返回参数值
}

async function init_menu_bar() {
    const username = localStorage.getItem("username");
    const menuId = getUrlParam('menuId');
    localStorage.setItem("menuId", menuId);

    const response = await fetch('/auth/menus?username=' + username);
    const res = await response.json();

    const menus = [];
    if (res.data) {
        const menuArray = res.data;
        for(const i in menuArray) {
            menus.push(menuArray[i]);
        }
        menus.push({
            menuId: 'logout',
            className: 'fa fa-sign-out',
            menuDes: '退出'
        })
    }
    return menus;
}

function menuChange(menuId) {
    localStorage.setItem("menuId", menuId);
    $("#menu_id").html(menuId);
    const pre_menu = $("a[class^='active']");
    pre_menu.removeClass("active");
    const current_menu = $("#" + menuId).children("a");
    const current_li = $("#" + menuId);
    current_menu.addClass('active');
    current_li.removeClass('clicked');
    $(".preloader").fadeOut();

    if (menuId === 'logout') {
        const username = localStorage.getItem("username");
        $.ajax({
            url: "/auth/logout?username=" + username,
            async: false,
            success: function(res) {
                localStorage.clear();
                window.location.href = '/login.html';
            }
        });
    }
}

const LeftSidebarNew = () => {
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        init_menu_bar()
            .then((list) => {
                setMenus(list);
            });
    }, []);

    return (
        <>
            <div className="left-sidebar" style={{marginTop: '10px'}}>
                <div className="scroll-sidebar">
                    <nav className="sidebar-nav" id="nav_header">
                        <ul id='sidebarnav'>
                            {(() => {
                                return menus.map((menu) => {
                                    let pageUrl = menu.menuId;
                                    switch (menu.menuId) {
                                        case 'dashuaibi':
                                            pageUrl = 'zhimingisdashuaibi';
                                            break;
                                        case 'special':
                                            pageUrl = 'different_setting';
                                            break;
                                        case 'special_list':
                                            pageUrl = 'different_setting_list';
                                            break;
                                        case "timing_special":
                                            pageUrl = 'crontab_different_setting';
                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        <li id={menu.menuId} key={menu.menuId} onClick={menuChange.bind(this, menu.menuId)}>
                                            {
                                                menu.menuId === 'special_setting' ?
                                                    <Link to="/different_config" target="_blank" rel="noopener noreferrer">
                                                        <i className={menu.className}/><span className="hide-menu">{menu.menuDes}</span>
                                                    </Link> :
                                                    <Link to={`${pageUrl}.html`}>
                                                        <i className={menu.className}/><span className="hide-menu">{menu.menuDes}</span>
                                                    </Link>
                                            }
                                        </li>
                                    )
                                })
                            })()}
                        </ul>
                    </nav>
                </div>
            </div>
            <Script url="/js/navi-menu.js"/>
        </>
    );
};

export default LeftSidebarNew;
