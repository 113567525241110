import React, { Component } from 'react'
import { List, Select, Input, Form, Row, Col, Button, Tooltip, Space, Skeleton, DatePicker } from 'antd';
import { FilterWrapper } from './filterWrapper';
import {
    FieldTimeOutlined,
    MailOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';

import axios from "axios";
import "./index.css";
import {Link} from "react-router-dom";
import moment from "moment";

class Home extends Component {
    state = {
        list: [],
        questionList: [
            "Questions and Comments about the App",
            "How to Manage and Cancel Your Subscription",
            "How to Request a Refund"
        ],
        formItemLayout: {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        },
        initLoading: true,
        loading: false,
        query: {
            platform: null,
            limit: 5,
            question_type: null,
            user_id: null,
            start_timestamp: null,
            end_timestamp: null
        },
        last: 0
    }
    componentDidMount () {
        this.getData();
    }

    getData = async (last) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.get("/api/v1/question/list", {
                    params: { ...this.removeNoSenseKeys(this.state.query), last }
                });
                const list = last ? [...this.state.list, ...result.data.data] : result.data.data;
                const resultList = list.filter((v) => !v.loading);
                this.setState({
                    initLoading: false,
                    list: resultList,
                    last: result.data.last
                });
            } catch (e) {
                reject(e);
            }
        })
    };

    onLoadMore = () => {
        this.setState({
            list: this.state.list.concat([
                ...new Array(this.state.query.limit)
            ].map(() => ({ loading: true })))
        })
        this.getData(this.state.last).then(() => {
            window.dispatchEvent(new Event('resize'));
        });
    }

    clickSearch = () => {
        this.setState({
            initLoading: true
        })
        this.getData();
    }

    platFormChange = (e) => {
        this.setState({
            query: {
                ...this.state.query,
                platform: e.target.value,
            }
        })
    }

    selectQuestion = (i) => {
        this.setState({
            query: {
                ...this.state.query,
                question_type: i,
            }
        })
    }

    userIdChange = (e) => {
        this.setState({
            query: {
                ...this.state.query,
                user_id: e.target.value,
            }
        })
    }

    dateChange = (e, date) => {
        this.setState({
            query: {
                ...this.state.query,
                start_timestamp: date[0] ? moment(date[0]).unix() * 1000 : null,
                end_timestamp: date[1] ? moment(date[1]).unix() * 1000 : null
            }
        })
    }

    removeNoSenseKeys = (resource) => {
        for (const key in resource) {
            if (resource.hasOwnProperty(key)) {
                resource[key] === undefined || resource[key] === null || resource[key] === ""
                    ? delete resource[key]
                    : void 0;
            }
        }
        return resource;
    }

    getQuestionContent = (i) => {
        return this.state.questionList[i];
    }

    timestampToTime = (date, fmt) => {
        const newDate = new Date(date);
        const o = {
            "M+": newDate.getMonth() + 1, // 月份
            "d+": newDate.getDate(), // 日
            "h+": newDate.getHours(), // 小时
            "m+": newDate.getMinutes(), // 分
            "s+": newDate.getSeconds(), // 秒
            "q+": Math.floor((newDate.getMonth() + 3) / 3), // 季度
            S: newDate.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                (newDate.getFullYear() + "").substr(4 - RegExp.$1.length)
            );
        }
        for (const k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1
                        ? "" + o[k]
                        : ("00" + o[k]).substr(("" + o[k]).length)
                );
            }
        }
        return fmt;
    }

    render () {
        const IconText = ({ icon, text }) => (
            <Space className="action-item">
                {React.createElement(icon)}
                {text}
            </Space>
        );

        const getActions = (item) => {
            const timeItem = <IconText
              icon={FieldTimeOutlined}
              text={
                  this.timestampToTime(
                    item.timestamp,
                    "yyyy-MM-dd hh:mm:ss"
                  )
              }
              key="list-vertical-star-o"
            />;
            const platformItem = <IconText
              icon={MailOutlined}
              text={item.platform}
              key="list-vertical-star-o"
            />;
            const questionItem = <IconText
              icon={QuestionCircleOutlined}
              text={this.getQuestionContent(item.question_type)}
              key="list-vertical-star-o"
            />;
            const result = [timeItem];
            platformItem && result.push(platformItem);
            this.getQuestionContent(item.question_type) && result.push(questionItem)
            return result;
        }
        const { initLoading, loading, last } = this.state;
        const loadMore =
            !initLoading && parseInt(last) !== -1 && !loading ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button onClick={this.onLoadMore}>loading more</Button>
                </div>
            ) : null;
        return (
          <div id="question-list-component">
              <div className="page-wrapper" style={{overflowAnchor: 'none', marginTop: '20px'}}>
                  <div className="row page-titles">
                      <div className="col-md-5 align-self-center">
                          <h3 className="text-primary">用户反馈信息</h3>
                      </div>
                      <div className="col-md-7 align-self-center">
                      </div>
                  </div>
                  <div className="container-fluid">
                      <div className="card" id="card" style={{display: 'inline-box'}}>
                          <FilterWrapper>
                              <Form>
                                  <Row gutter={24}>
                                      <Col span={6}>
                                          <Form.Item style={{ marginBottom: 0 }} label="platform">
                                              <Input
                                                onChange={this.platFormChange}
                                                style={{ width: 200 }}
                                                placeholder="please input platform"
                                                allowClear
                                              />
                                          </Form.Item>
                                      </Col>
                                      <Col span={6}>
                                          <Form.Item onChange={this.userIdChange} style={{ marginBottom: 0 }} label="userId">
                                              <Input
                                                style={{ width: 200 }}
                                                placeholder="please input userId"
                                                allowClear
                                              />
                                          </Form.Item>
                                      </Col>
                                      <Col span={9}>
                                          <Form.Item onChange={this.userIdChange} style={{ marginBottom: 0 }} label="rangePicker">
                                              <DatePicker.RangePicker style={{ width: 300 }} onChange={this.dateChange}></DatePicker.RangePicker>
                                          </Form.Item>
                                      </Col>
                                  </Row>
                                  <Row gutter={24} style={{ marginTop: "20px" }}>
                                      <Col span={6}>
                                          <Form.Item style={{ marginBottom: 0 }} label="question">
                                              <Select
                                                style={{ width: 200 }}
                                                placeholder="Select a question"
                                                showSearch
                                                onChange={this.selectQuestion}
                                                filterOption={
                                                    (input, option) => {
                                                        return option.children.props.title.toLowerCase()
                                                          .indexOf(input.toLowerCase()) >= 0;
                                                    }
                                                }
                                                allowClear
                                              >
                                                  {
                                                      this.state.questionList.map((item, i) => {
                                                          return (
                                                            <Select.Option value={i} key={`listTool-select-${i}`}>
                                                                <Tooltip title={item}>{item}</Tooltip>
                                                            </Select.Option>
                                                          );
                                                      })
                                                  }
                                              </Select>
                                          </Form.Item>
                                      </Col>
                                      <Col span={2}>
                                          <Button onClick={() => this.clickSearch()} type="primary">查询</Button>
                                      </Col>
                                      <Col span={4}>
                                          <Link to="/page/question_platform_to_email.html">
                                              <Button type="primary">平台与邮箱映射</Button>
                                          </Link>
                                      </Col>
                                  </Row>
                              </Form>
                          </FilterWrapper>
                          <List
                            itemLayout="vertical"
                            loading={initLoading}
                            loadMore={loadMore}
                            size="large"
                            style={{paddingBottom: "12px"}}
                            dataSource={this.state.list}
                            renderItem={item => (
                              <Skeleton loading={item.loading} active className="skeleton-padding">
                                  <List.Item
                                    key={item.name + item.timestamp}
                                    actions={getActions(item)}
                                  >
                                      <List.Item.Meta
                                        title={item.name}
                                        description={item.email}
                                      />
                                      {item.suggestion}
                                  </List.Item>
                              </Skeleton>
                            )}
                          />
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}
export default Home
