import React, { useState, useEffect, useRef } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/selection/active-line';

import { Input, AutoComplete, Button } from 'antd';
import ReactDiffViewer from 'react-diff-viewer';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import './Setting.scss';

let tolConfig = {};
let curType = "client";
let curKey = "";
let database_config_content = "";
let Platform1 = "";
let Platform2 = "";
let diffConfigType = "client";
let allPlatformList = [];
let allIapCheckPlatformList = [];


const Setting = () => {
    const codeEditorRef = useRef();
    const iapCheckEditorRef = useRef();

    const [configListData, setConfigListData] = useState([]);
    const [platformAutoComplete, setPlatformAutoComplete] = useState([]);
    const [iapCheckPlatformAutoComplete, setIapCheckPlatformAutoComplete] = useState([]);

    const [twoCofnigList, setTwoCofnigList] = useState([]);

    const [platform1ConfigValue, setPlatform1ConfigValue] = useState('');
    const [platform2ConfigValue, setPlatform2ConfigValue] = useState('');
    const [splitView, setSplitView] = useState(true);

    const [preConfigValue, setPreConfigValue] = useState('');
    const [currentConfigValue, setCurrentConfigValue] = useState('');
    const [splitView1, setSplitView1] = useState(true);

    /**
     * 点击菜单中的某个key，展现某一条key的value
     * 并更新curKey
     * @param {*} key
     */
    async function showConfigItem(key, codeEditorRef) {
        const editor = codeEditorRef.current.editor;
        editor.setValue('');
        $("#" + key).addClass("clicked").siblings().removeClass("clicked");
        $("#config_item_lable").empty();
        $("#config_item_lable").html("Start get config ...");
        let platform = $("#platform_input").val();
        curKey = key;
        await querySingle(platform, curType, curKey);
        $("#config_item_lable").html(curKey);
        let content = tolConfig[key];
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }
        content = formatJson(content);
        database_config_content = content;
        editor.setValue(content);
    }

    /**
     * 点击保存时，保存输入框中内容
     */
    async function save() {
        const editor = codeEditorRef.current.editor;
        let configValue = editor.getValue();
        if (!curKey || !configValue) {
            alert("缺少参数");
            return;
        }
        let r = confirm("确认输入内容无误，继续保存吗？");
        if (!r) { return; }

        const formatValue = formatJson(configValue);
        if (curKey === "review_version" && configValue !== formatValue) {
            alert("不支持的review_version，请重新编辑");
            return;
        }
        configValue = formatValue;

        try {
            configValue = JSON.parse(configValue);
        } catch (e) {
            configValue = configValue.trim();
        }
        if (typeof(configValue) == "object") {
            configValue = JSON.stringify(configValue, null, 4);
        }

        updateConfig(curKey, configValue);
    }

    /**
     * 点击增加的按键时，经过用户确认，就会新建一个value为空的key
     */
    async function addConfigBtn() {
        let configKey = $("#newConfigItem").val();
        if (!configKey) {
            alert("请输入新的config名称");
            return;
        }
        let r = confirm("确认新建" + configKey + "吗？");
        if (!r) { return; }
        await addConfig(configKey);
        let platform = $("#platform_input").val();
        let allKey = await queryAll(platform, curType);
        // buildMenu(allKey, "configList");
        setConfigListData(allKey);
        $("#" + curKey).addClass("clicked").siblings().removeClass("clicked");
        showConfigItem(configKey, codeEditorRef);
    }

    /**
     * 点击格式化时，将输入框中的json格式化
     */
    async function format() {
        const editor = codeEditorRef.current.editor;
        let content = editor.getValue();
        let result = formatJson(content);

        if (result) {
            editor.setValue(result);
        }
    }

    /**
     * 是否包含全角空格或半角空格
     * @param str
     * @returns {boolean}
     */
    function contain_blank(str) {
        if (!str) {
            return false;
        }

        return str.indexOf(' ') !== -1 || str.indexOf('　') !== -1;
    }

    /**
     * 点击添加平台按钮时，新增平台信息进数据库
     * @returns {Promise<void>}
     */
    async function addPlatformBtn() {
        const platform = $("#new_platform_name_input").val();
        const group = platform + '-';
        const market = $("#new_market_select").val();
        const new_product_id_prefix_input = $("#new_product_id_prefix_input").val();
        const new_source_platform_input = $("#new_source_platform_input").val();
        if (!platform) {
            alert("未填写platform");
            return;
        }
        if (!market || (market !== "iOS" && market !== "Android" && market !== "Web")) {
            alert("market填写有误");
            return;
        }

        if(contain_blank(market) || contain_blank(platform)
            || contain_blank(group) || contain_blank(new_source_platform_input)
            || contain_blank(new_source_platform_input) ){
            alert("输入参数中不能包含空格");
            return;
        }

        const platforms = await getPlatformsByGroup(group);
        let notice = '';
        if (platforms.length > 0) {
            notice = group + ', 该group已被其它平台占用:\n';
            platforms.forEach((one) => {
                notice += '   ' + one + ',\n'
            });
            notice += '\n请更换平台名~\n';
            alert(notice);
            return;
        }

        await addPlatform(platform, group, market, new_product_id_prefix_input, new_source_platform_input);
        $("#new_platform_name_input").val("");
        $("#new_market_select").val("none");
        $("#new_product_id_prefix_input").val("");
        $("#new_source_platform_input").val("");
        for (let i = 0; i <= 60000000; i++) {
        }
        await queryAllPlatform();
    }

    async function addPlatformPlug() {
        const platform = $("#new_source_platform_plug_input").val();
        if (!platform || !platform.trim()) {
            alert("未填写platform");
            return;
        }

        window.open(
            `${window.location.origin}/quark_app/?platform=${platform}`,
            "newwindow",
            "height=700,left=100,width=1000,toolbar=no,menubar=no,scrollbars=no, resizable=yes, location=yes, status=yes"
        );
    }

    /**
     * 复制平台tab点击刷新按钮时，查找该平台该config type下所有的setting项
     * 并通过setting数组画出复选菜单
     * @returns {Promise<void>}
     */
    async function copyRefresh() {
        let platform = $("#from_platform_name").val();
        let configType = $("#config_type").val();
        if (configType !== "all") {
            let allKey = await queryAll(platform, configType);
            buldCheckboxList(allKey, "setting_checkbox_list");
        }
    }
/////////////////////////////////////////////////////////////////////////////////////
// 网络请求函数
/////////////////////////////////////////////////////////////////////////////////////

    async function getPlatformsByGroup(group_name) {
        return new Promise(async (resolve, reject) => {
            if (!group_name) {
                alert("缺少group名");
                return;
            }
            const username = localStorage.getItem("username");
            $.ajax({
                type: "GET",
                url: "/api/v1/config/platform_by_group?username=" + username + "&menuId=setting" + "&groupName=" + group_name,
                success: function (data) {
                    if (data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        } else {
                            alert("查询group平台失败：" + data.err);
                            return;
                        }
                    } else {
                        resolve(data.platforms);
                    }
                },
                error: function (req, status, err) {
                    const message = err.message || err;
                    alert("网络出问题啦(根据group获取platform失败), 错误原因:" + message);
                },
            });
        });
    }
    /**
     * 新增平台S
     * @param platform
     * @param group
     * @param market
     * @returns {Promise<void>}
     */
    async function addPlatform(platform, group, market, new_product_id_prefix_input, new_source_platform_input) {
        const username = localStorage.getItem("username");
        $.ajax({
            type: "POST",
            url: "/api/v1/config/platform?username=" + username + "&menuId=setting",
            data: {
                platform: platform,
                group: group,
                market: market,
                product_id_prefix: new_product_id_prefix_input,
                be_copied_platform: new_source_platform_input,
            },
            success: function(data) {
                if (data.err) {
                    if (data.isReload) {
                        window.location.href = "/login.html";
                        return;
                    } else {
                        alert("添加失败：" + data.err);
                        return;
                    }
                } else {
                    if (new_source_platform_input) {
                        alert("平台添加成功，并成功复制了 "+new_source_platform_input+" 的配置！");
                    } else {
                        alert("平台添加成功，请填写该平台其它参数");
                    }
                }
            },
            error: function(req, status, err) {
                const message = err.message || err;
                alert("网络出问题啦(添加platform失败)，请稍后重试。错误原因:" + message);
            },
        });
    }
    /**
     * 获得某平台的某个configtype下的所有配置
     * 并且更新菜单
     * @param {*} platform
     * @param {*} configType
     */
    async function queryAll(platform, configType) {
        return new Promise(async (resolve, reject) => {
            if (!configType) {
                alert("缺少Type");
                return;
            }
            if (!platform) {
                alert("缺少平台名");
                return;
            }
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/config/content?platform=" + platform + "&config_type=" + configType + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("Query Failed：" + data.err);
                        return;
                    } else {
                        tolConfig = data.data[configType];
                        // 获得所有key组成的数组
                        let allKey = Object.keys(tolConfig);
                        resolve(allKey);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("网络出问题啦(查询配置失败)，请稍后重试。错误原因:" + message);
                }
            });
        });
    }

    /**
     * 获得某平台的某个configtype下的单个配置
     * 并且更新菜单
     * @param {*} platform
     * @param {*} configType
     * @param {*} settingName
     */
    async function querySingle(platform, configType, settingName) {
        return new Promise(async (resolve, reject) => {
            if (!configType) {
                alert("缺少Type");
                return;
            }
            if (!platform) {
                alert("缺少平台名");
                return;
            }
            if (!settingName) {
                alert("未选中配置");
                return;
            }
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/config/single_config?platform=" + platform + "&config_type=" + configType + "&setting_name=" + settingName + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("Query Failed：" + data.err);
                        reject(data.err);
                    } else {
                        tolConfig[settingName] = data.data.setting_value;
                        resolve();
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("网络出问题啦(查询配置失败)，请稍后重试。错误原因:" + message);
                },
            });
        });
    }

    /**
     * 更新config的内容
     * @param {*} configKey
     * @param {*} configValue
     */
    async function updateConfig(configKey, configValue) {
        return new Promise(async (resolve, reject) => {
            let platform = $("#platform_input").val();
            let username = localStorage.getItem("username");
            if (!platform) {
                alert("请输入平台名");
                return;
            }
            $.ajax({
                type: "POST",
                url: "/api/v1/config/update_config?username=" + username + "&menuId=setting",
                data: {
                    platform: platform,
                    config_type: curType,
                    setting_name: configKey,
                    setting_value: configValue,
                },
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("提交失败：" + data.err);
                        reject(data.err);
                    } else {
                        alert("Change Success.");
                        resolve();
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("更新配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                },
            });
        });
    }

    /**
     *
     * @param {*} configKey
     */
    async function addConfig(configKey) {
        return new Promise(async (resolve, reject) => {
            let platform = $("#platform_input").val();
            let username = localStorage.getItem("username");
            if (!platform) {
                alert("请输入平台名");
                return;
            }
            $.ajax({
                type: "POST",
                url: "/api/v1/config/config?username=" + username + "&menuId=setting",
                data: {
                    platform: platform,
                    config_type: curType,
                    setting_name: configKey,
                    setting_value: "",
                },
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("提交失败：" + data.err);
                        reject(data.err);
                    } else {
                        alert("Change Success.");
                        resolve();
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("添加配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                },
            });
        });
    }

    function onSearchPlatformAutoComplete (txt) {
        const filterPlatforms = allPlatformList.filter((platform) => {
            return platform.toLowerCase().indexOf(txt.toLowerCase()) > -1;
        });
        setPlatformAutoComplete(filterPlatforms.map((platform) => {
            return {value: platform};
        }));
    }

    function onSearchIapCheckPlatformAutoComplete (txt) {
        const filterPlatforms = allIapCheckPlatformList.filter((platform) => {
            return platform.toLowerCase().indexOf(txt.toLowerCase()) > -1;
        });
        setIapCheckPlatformAutoComplete(filterPlatforms.map((platform) => {
            return {value: platform};
        }));
    }

    /**
     * 拉取所有平台名称
     * @returns {Promise<void>}
     */
    async function queryAllPlatform() {
        let username = localStorage.getItem("username");
        let menuId = $("#menu_id").html();
        $.ajax({
            type: "GET",
            url: "/api/v1/config/platform" + '?username=' + username + '&menuId=' + menuId,
            success: function(data) {
                if (data.err) {
                    if (data.isReload) {
                        window.location.href = "/login.html";
                    } else {
                        alert(data.err);
                    }
                    return;
                }
                data = data.data;
                if (data && data.length) {
                    /* data.sort(function(a, b) {
                         return a.timestamp - b.timestamp;
                     });*/

                    let allPlatform = [];
                    for (let p of data) {
                        allPlatform.push(p);
                    }
                    allPlatformList = allPlatform;
                    // $("#platform_input").autocomplete({
                    //     source: allPlatform,
                    // });
                    // $("#from_platform_name").autocomplete({
                    //     source: allPlatform,
                    // });
                    // $("#target_platform_name").autocomplete({
                    //     source: allPlatform,
                    // });
                    // $("#new_source_platform_input").autocomplete({
                    //     source: allPlatform,
                    // });
                    // $("#new_source_platform_plug_input").autocomplete({
                    //     source: allPlatform,
                    // });
                    // $("#first_platform_input").autocomplete({
                    //     source: allPlatform,
                    // });
                    // $("#second_platform_input").autocomplete({
                    //     source: allPlatform,
                    // });
                    const regex = new RegExp("android_.+._iapcheck");
                    allIapCheckPlatformList = allPlatform.filter((item) => {
                        return regex.test(item.toLowerCase())
                    });
                    // $("#iap_check_platform_input").autocomplete({
                    //     source: filterIapCheckPlatforms,
                    // });
                    //
                    // $("#different_setting_platform").autocomplete({
                    //     source: allPlatform,
                    // });
                    //
                    // $("#different_setting_group_platform").autocomplete({
                    //     source: allPlatform,
                    // });

                }
            },
            error: function(req, status, err) {
                const message = err.message || err;
                alert("查询所有平台失败，网络出问题了，请稍后重试。错误原因:" + message);
            }
        });
    }

    /**
     * 将一个平台的选中的配置复制到另一个平台
     * @param isAll
     * @param beCopiedPlatform
     * @param targetPlatform
     * @param configType
     * @param settingNames
     * @returns {Promise<void>}
     */
    async function copyPlatform() {
        let username = localStorage.getItem("username");
        let beCopiedPlatform = $("#from_platform_name").val();
        let targetPlatform = $("#target_platform_name").val();
        let configType = $("#config_type").val();
        let productIdPrefix = $("#product_id_prefix").val();
        if (!beCopiedPlatform) {
            alert("请填写from platform");
            return;
        }
        if (!targetPlatform) {
            alert("请填写target platform");
            return;
        }
        if (configType === "all") {
            $.ajax({
                type: "POST",
                url: "/api/v1/config/copy_platform?username=" + username + "&menuId=setting",
                data: {
                    is_all: true,
                    be_copied_platform: beCopiedPlatform,
                    target_platform: targetPlatform,
                    product_id_prefix: productIdPrefix,
                    config_type: "",
                    setting_names: "",
                },
                success: function(data) {
                    if (data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        } else {
                            alert("复制失败" + data.err);
                            return;
                        }
                    } else {
                        alert("平台复制成功");
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("复制平台配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                }
            });
        } else {
            let settingNames = await getCheckList();
            $.ajax({
                type: "POST",
                url: "/api/v1/config/copy_platform?username=" + username + "&menuId=setting",
                data: {
                    is_all: null,
                    be_copied_platform: beCopiedPlatform,
                    target_platform: targetPlatform,
                    config_type: configType,
                    setting_names: settingNames,
                    product_id_prefix: productIdPrefix,
                },
                success: function(data) {
                    if (data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        } else {
                            alert("复制失败" + data.err);
                            return;
                        }
                    } else {
                        alert("平台复制成功");
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("复制平台配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                },
            });
        }
    }
/////////////////////////////////////////////////////////////////////////////////////
// controller函数
/////////////////////////////////////////////////////////////////////////////////////

    /**
     * 输入一个数组和需要加入列表的id，直接生成菜单栏
     * @param {*} list
     * @param {*} id
     */
    async function buildMenu(list, id) {
        $("#" + id).empty();
        let len = list.length;
        let html = "";
        for (let i = 0; i < len; i++) {
            let key = list[i];
            html = '<li id="' + key + '" onclick="showConfigItem(\'' + key + '\')" style="height: 35px"><a href="#" style="font-size: small;color: black;">' + key + "</a></li>";
            $("#" + id).append(html);
        }
    }

    /**
     * 用在复制平台tab
     * 根据数组和列表id，生成复选框菜单栏
     * @param list
     * @param id
     * @returns {Promise<void>}
     */
    async function  buldCheckboxList(list, id) {
        $("#" + id).empty();
        let len = list.length;
        let html = "";
        for (let i = 0; i < len; i++) {
            let key = list[i];
            html = '<div> <input type="checkbox" value="' + key + '"><a>' + key + "</a></div>";
            $("#" + id).append(html);
        }
    }

    /**
     * 格式化JSON
     */
    function formatJson(content) {
        if (!content) {
            return "";
        }
        try {
            content = JSON.parse(content);
        } catch (e) {
            content = content.replace(/\s/g, "");
            try {
                const objJsonParsed = content.includes("{") && content.includes("}");
                const arrayJsonParsed = content.includes("[") && content.includes("]");
                const numberJsonParsed = content.match(new RegExp(/^[0-9]*$/));
                if (objJsonParsed || arrayJsonParsed || numberJsonParsed) {
                    content = JSON.parse(content);
                }
            } catch (e) {
                alert(e);
                return;
            }
        }
        if (typeof(content) == "object") {
            content = JSON.stringify(content, null, 4);
        }
        if (typeof (content) === "number") {
            content = content.toString();
        }
        return content;
    }

    function diffUsingJS(old_content, editorRef, display_diff_div_obj, view_type) {
        setPreConfigValue(old_content);
        setCurrentConfigValue(editorRef.current.editor.getValue());
        setSplitView1(!view_type);
    }

    /**
     * 取出复选框列表中选中的选项
     * 返回字符串
     * @returns {Promise<Array>}
     */
    async function getCheckList() {
        let checked = "";
        $("input:checkbox").each(function() {
            if ($(this).attr("checked")) {
                checked += ($(this).val());
                checked += ",";
            }
        });
        checked = checked.substr(0, checked.length - 1);
        return checked;
    }

    $("li").click(function() {
        $(this).addClass("clicked").siblings().removeClass("clicked");
    });

    $("#btn_check").click(function() {
        // alert('格式正确');
        diffUsingJS(database_config_content, codeEditorRef, $("#diffoutput"), 0);
        $("#text_diff_modal").css({
            "width": "100%",
            // "margin-left": "-50%",
            "height": "95%",
            "margin-top": "-70px",
            "background-color": "white",
            "top": "90px",
            "position": "fixed",
            "overflow-x": "auto",
        });
        $("h3").css({
            "position": "relative",
            "left": "-30%",
        });
        $("#text_diff_modal div.modal-body").css({
            "max-height": "100%",
            "padding-bottom": "20px",
        });
        $("#diffoutput").css({
            "max-height": window.screen.availHeight - 120 + "px",
        });
        $("#text_diff_modal").modal();
    });

    function opVipBtn() {
        let username = localStorage.getItem("username");
        const platformName = $('#platformName').val();
        const userId = $('#userId').val();
        const opType = $("#vip_op_select").val();
        if (!platformName) {
            alert("缺少platform");
            return;
        }
        if (!userId) {
            alert("缺少userId");
            return;
        }
        let reqType = (opType === 'add') ? "POST" : "DELETE";

        $.ajax({
            type: reqType,
            url: '/auth/vip?username='+ username + '&menuId=setting',
            data: {user_id: userId, platform: platformName},
            success: function (data) {
                const flag = data.status ? '成功' : '失败';
                alert(`设置VIP${flag}, ${data.message}`);
            }
        })
    }

    /**
     * 当两个平台名都有值时，默认获取client的所有配置项名，取并集
     * @returns {Promise<void>}
     */
    async function checkInputValue() {
        const platform1 = $('#first_platform_input').val();
        const platform2 = $('#second_platform_input').val();
        // console.log(platform1, platform2);
        if (platform1 && platform2) {
            Platform1 = platform1; // 更改变量值，留作其他地方使用
            Platform2 = platform2;
            await buildDiffMenu(Platform1, Platform2)
        }
    }

    /**
     * 根据两个平台名生成左侧配置栏
     * @param {*} platform1
     * @param {*} platform2
     */
    async function buildDiffMenu(platform1, platform2) {
        const platform1Config = await queryAllConfig(platform1, diffConfigType);
        const platform2Config = await queryAllConfig(platform2, diffConfigType);
        // console.log(platform1Config);
        // console.log(platform2Config);
        const list = Array.from(new Set(platform1Config.concat(platform2Config)));
        setTwoCofnigList(list);
        // $("#twoConfigList").empty();
        // let len = list.length;
        // let html = "";
        // for (let i = 0; i < len; i++) {
        //     let key = list[i];
        //     html = '<li id="' + key + '" onclick="showConfigDiff(\'' + key + '\')" style="height: 35px"><a href="###" style="font-size: small;color: black;">' + key + "</a></li>";
        //     $("#twoConfigList").append(html);
        // }
    }

    async function showConfigDiff (key) {
        // $("#two_platform_config_diff").empty();
        setPlatform1ConfigValue('');
        setPlatform2ConfigValue('');
        let platform1ConfigValue = await querySingleConfig(Platform1, diffConfigType, key);
        let platform2ConfigValue = await querySingleConfig(Platform2, diffConfigType, key);
        platform2ConfigValue = platform2ConfigValue ? JSON.stringify(platform2ConfigValue, null, 2) : '';
        platform1ConfigValue = platform1ConfigValue ? JSON.stringify(platform1ConfigValue, null, 2) : '';
        diffTwoConfigUsingJS(platform1ConfigValue, platform2ConfigValue, $("#two_platform_config_diff"), 0);
        $("#" + key).addClass("lactive").siblings().removeClass("lactive");
    }

    async function diffTabChange (configType) {
        setTwoCofnigList([]);
        setPlatform1ConfigValue('');
        setPlatform2ConfigValue('');
        diffConfigType = configType;
        await buildDiffMenu(Platform1, Platform2);
    }

    /**
     * 获得某平台的某个configtype下的所有配置
     * @param {*} platform
     * @param {*} configType
     */
    async function queryAllConfig(platform, configType) {
        return new Promise(async (resolve, reject) => {
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/config/content?platform=" + platform + "&config_type=" + configType + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("Query Failed：" + data.err);
                        return;
                    } else {
                        // 获得所有key组成的数组
                        let allKey = Object.keys(data.data[configType]);
                        resolve(allKey);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("查询配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                },
            });
        });
    }

    /**
     * 获得某平台的某个configtype下的单个配置
     * @param {*} platform
     * @param {*} configType
     * @param {*} settingName
     */
    async function querySingleConfig(platform, configType, settingName) {
        return new Promise(async (resolve, reject) => {
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/config/single_config?platform=" + platform + "&config_type=" + configType + "&setting_name=" + settingName + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        if (data === undefined) {
                            resolve({})
                        }
                        alert("Query Failed：" + data.err);
                        reject(data.err);
                    } else {
                        resolve(data.data.setting_value);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("查询配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                },
            });
        });
    }

    function diffTwoConfigUsingJS(old_content, new_content) {
        setPlatform1ConfigValue(old_content);
        setPlatform2ConfigValue(new_content);
    }


    function refreshIapCheck() {
        const editor1 = iapCheckEditorRef.current.editor;
        const platform = $("#iap_check_platform_input").val();
        if (!platform) {
            alert("please choose a platform");
        } else {
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();

            $.ajax({
                type: "GET",
                url: "/api/v1/config/check_refresh_token?platform=" + platform  + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (data.status === 200) {
                        alert("验证成功");
                        const content = data.iap_check;
                        let result = formatJson(content);
                        editor1.setValue(result);
                    } else {
                        alert(data.message);
                    }
                },
                error: function(req, status) {
                    console.log("验证失败", status, req);
                },
            });
        }
    }

    function iapOrderCheck() {
        const platform = $("#iap_order_check_platform_input").val().trim();
        const receipt = $("#receipt_value").val().trim();
        if (!platform) {
            alert("please input a platform");
        } else if(!receipt) {
            alert("please input receipt");
        } else {
            $("#check_result").text('loading...');
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            $.ajax({
                type: "POST",
                url: "/api/v1/verify?username=" + username + "&menuId=" + menuId,
                data: {
                    platform: platform,
                    receipt: receipt,
                },
                success: function (data) {
                    let message = '';
                    if (data.err) {
                        message = data.err;
                    } else {
                        message = data && data.data;
                        message = JSON.stringify(message, null, 4)
                    }

                    $("#check_result").text(message);
                },
                error: function (req, status, err) {
                    const message = err.message || err;
                    alert("查询配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                }
            });
        }
    }

    function setOriginIapCheck() {
        const editor1 = iapCheckEditorRef.current.editor;
        const platform = $("#iap_check_platform_input").val();
        let username = localStorage.getItem("username");
        let menuId = $("#menu_id").html();

        $.ajax({
            type: "GET",
            url: "/api/v1/config/single_config?platform=" + platform + "&config_type=server" +  + "&setting_name=iap_check" + '&username=' + username + '&menuId=' + menuId,
            success: function(data) {
                if (!data || data.err) {
                    if (data.isReload) {
                        window.location.href = "/login.html";
                        return;
                    }
                    const message = data.err.message || data.err;
                    alert("网络出问题了，请稍后重试。错误原因:" + message);
                } else {
                    let result = formatJson(data.data.setting_value);
                    editor1.setValue(result);
                }
            }
        });
    }

    async function refresh() {
        const editor = codeEditorRef.current.editor;
        editor.setValue('');
        let platform = $("#platform_input").val();
        let allKey = await queryAll(platform, curType);
        setConfigListData(allKey);
        // buildMenu(allKey, "configList");
        // $("#" + curKey).addClass("clicked").siblings().removeClass("clicked");
        // if (curKey) {
        //     showConfigItem(curKey);
        // }
    }

    async function tabChange(type) {
        const editor = codeEditorRef.current.editor;
        // $("#configList").empty();
        setConfigListData([]);
        $("#config_item_lable").empty();
        editor.setValue("");
        curType = type;
        curKey = "";
        let platform = $("#platform_input").val();
        // 如果有平台名就query，没平台名就正常跳转
        if (platform) {
            let allKey = await queryAll(platform, curType);
            // buildMenu(allKey, "configList");
            setConfigListData(allKey);
            $("#" + curKey).addClass("clicked").siblings().removeClass("clicked");
        }
    }

    useEffect(() => {
        $(".CodeMirror-scroll").hover(function() {
            $(this).get(0).style.cursor = "text";
        });
        codeEditorRef.current.editor.setSize('auto', 'auto');
        iapCheckEditorRef.current.editor.setSize('auto', 'auto');

        queryAllPlatform();
    }, []);

    return (
        <div id="setting-component">
            <div className="page-wrapper" style={{overflowAnchor: 'none', marginTop: '20px'}}>
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-info" style={{display: 'inline-block'}}>
                            Config
                        </h3>
                    </div>
                    <div className="col-md-7 align-self-center">
                    </div>
                </div>
                <div className="container-fluid" style={{overflowX: 'scroll'}}>
                    <ul className="nav nav-tabs customtab2" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active show" data-toggle="tab" href="#Pane" role="tab"
                               aria-selected="true" onClick={() => {curType = 'client'; tabChange('client', codeEditorRef)}}
                            >
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  Client
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#Pane" role="tab" aria-selected="false"
                               onClick={() => {curType = 'common'; tabChange('common', codeEditorRef)}}>
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  Common
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" href="#Pane" role="tab" aria-selected="false"
                               onClick={() => {curType = 'server'; tabChange('server', codeEditorRef)}}>
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  Server
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" id="add_platform_plug_li"
                               href="#add_platform_plug_pane" role="tab" aria-selected="false">
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  插件
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" id="add_platform_li" href="#add_platform_pane"
                               role="tab" aria-selected="false">
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  添加新平台
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" id="batch_copy_li" href="#batch_copy_pane"
                               role="tab" aria-selected="false">
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  复制平台
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" id="del_set_vip" href="#op_vip_pane" role="tab"
                               aria-selected="false">
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  VIP
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" id="diff_two_platform"
                               href="#op_diff_two_platform" role="tab" aria-selected="false">
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  比较配置
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" id="iap_check_nav" href="#op_iap_check" role="tab"
                               aria-selected="false">
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  验证Google RefreshToken
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" id="iap_order_check" href="#op_iap_order_check"
                               role="tab" aria-selected="false">
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  校验IAP订单
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" style={{minWidth: '1296px'}}>
                        <div className="tab-pane active show" id="Pane" role="tabpanel"
                             style={{overflowX: 'hidden', overflowY: 'hidden'}}>
                            <div id="change-col" className="col">
                                <div className="inbox-leftbar" style={{marginTop: '24px'}}>
                                    <div>
                                        <Input id="newConfigItem" type="text" className="form-control input-rounded"
                                               placeholder="新增config" style={{
                                            height: '30px',
                                            width: '150px',
                                            fontSize: 'small',
                                            position: 'relative'
                                        }}/>
                                        <button className="btn-info " type="button" onClick={addConfigBtn.bind(this)}
                                                style={{position: 'relative', left: '2px'}}>
                                            +
                                        </button>
                                    </div>
                                    <div className="mail-list mt-4" style={{
                                        borderRadius: '.9rem',
                                        background: 'white',
                                        position: 'relative',
                                        top: '-10px'
                                    }}>
                                        <div id="configList" style={{
                                            position: 'relative',
                                            top: '50px',
                                            textAlign: 'center',
                                            minHeight: '300px'
                                        }}>
                                            {(() => {
                                                return configListData.map((item) => {
                                                    return (
                                                        <li
                                                            id={item}
                                                            key={item}
                                                            onClick={showConfigItem.bind(this, item, codeEditorRef)}
                                                            style={{height: '35px'}}
                                                        >
                                                            <a href="#" style={{fontSize: 'small',color: 'black'}}>{item}</a>
                                                        </li>
                                                    )
                                                });
                                            })()}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: '20px'}}>
                                    <div className="card" style={{width: '100%', height: '80px', position: 'relative'}}>
                                        <div className="row">
                                            <AutoComplete
                                                options={platformAutoComplete}
                                                style={{
                                                    width: 200,
                                                    left: 20
                                                }}
                                                id="platform_input"
                                                onSearch={onSearchPlatformAutoComplete}
                                                placeholder="platform name"
                                            />
                                            <p id="write_status" style={{
                                                position: 'relative',
                                                top: '10px',
                                                left: '50px',
                                                fontSize: 'small'
                                            }}>
                                                Config Item:
                                                <span id="config_item_lable">
                        </span>
                                            </p>
                                            <button id="btn_refresh" className="btn btn-info btn-flat m-b-10 "
                                                    type="button" style={{
                                                width: '40px',
                                                height: '40px',
                                                position: 'absolute',
                                                left: '550px'
                                            }} onClick={refresh.bind(this)}>
                                                <i className="fa fa-refresh">
                                                </i>
                                            </button>
                                            <button id="btn_submit" className="btn btn-info btn-flat m-b-10 "
                                                    type="button" style={{
                                                width: '40px',
                                                height: '40px',
                                                position: 'absolute',
                                                left: '600px'
                                            }} onClick={save.bind(this)}>
                                                <i className="fa fa-save">
                                                </i>
                                            </button>
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: '70px',
                                                    height: '40px',
                                                    position: 'absolute',
                                                    left: '650px'
                                                }}
                                                onClick={format}
                                            >
                                                格式化
                                            </Button>
                                            <Button
                                                type="primary"
                                                id="btn_check"
                                                style={{
                                                    width: '60px',
                                                    height: '40px',
                                                    position: 'absolute',
                                                    left: '730px'
                                                }}
                                            >
                                                校验
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: '20px'}}>
                                    <div className="card" style={{width: '100%', position: 'relative'}}>
                                        <div>
                                            <CodeMirror
                                                id="code"
                                                className="config_content"
                                                ref={codeEditorRef}
                                                value=''
                                                options={{
                                                    mode: 'application/ld+json',
                                                    lineNumbers: true,
                                                    indentUnit: 4
                                                }}
                                                onChange={(editor, data, value) => {
                                                    //
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-20" id="add_platform_plug_pane" role="tabpanel">
                            <div className="row justify-content-center">
                                <div className="col-9">
                                    <div className="card card-outline-info">
                                        <div className="card-header">
                                            <h4 className="m-b-0 text-white">
                                                维护插件
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <form action="#">
                                                <div className="form-body">
                                                    <hr/>
                                                    <div className="row p-t-20">
                                                        <div className="col-md-6">
                                                            <div className="form-group has-danger">
                                                                <label className="control-label">
                                                                    Platform
                                                                </label>
                                                                {/*<input type="text" id="new_source_platform_plug_input"*/}
                                                                       {/*className="form-control form-control-danger"*/}
                                                                       {/*placeholder="iOS_Test"/>*/}
                                                                <AutoComplete
                                                                    options={platformAutoComplete}
                                                                    style={{
                                                                        display: 'block',
                                                                        width: '100%',
                                                                    }}
                                                                    id="new_source_platform_plug_input"
                                                                    onSearch={onSearchPlatformAutoComplete}
                                                                    placeholder="iOS_Test"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr/>
                                                </div>
                                            </form>
                                            <div className="row justify-content-center">
                                                <div>
                                                    <button onClick={addPlatformPlug.bind(this)} type="submit"
                                                            className="btn btn-info"
                                                            style={{width: '200px', height: '40px'}}>
                                                        <i className="fa fa-check">
                                                        </i>
                                                        OK
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-20" id="add_platform_pane" role="tabpanel">
                            <div className="row justify-content-center">
                                <div className="col-9">
                                    <div className="card card-outline-info">
                                        <div className="card-header">
                                            <h4 className="m-b-0 text-white">
                                                添加新平台
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <form action="#">
                                                <div className="form-body">
                                                    <hr/>
                                                    <div className="row p-t-20">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    平台名
                                                                </label>
                                                                <Input type="text" id="new_platform_name_input"
                                                                       className="form-control" placeholder="iOS_Test"/>
                                                                <small className="form-control-feedback"
                                                                       id="whether_test">
                                                                    平台名中包含连续的test/Test字符将被判定为测试平台
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group has-danger">
                                                                <label className="control-label">
                                                                    Bundle id (Product id prefix)
                                                                </label>
                                                                <Input type="text" id="new_product_id_prefix_input"
                                                                       className="form-control form-control-danger"
                                                                       placeholder="com.xx.oo"/>
                                                                <small className="form-control-feedback">
                                                                    格式是com.xx.oo
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group has-danger">
                                                                <label className="control-label">
                                                                    From Platform
                                                                </label>
                                                                {/*<input type="text" id="new_source_platform_input"*/}
                                                                       {/*className="form-control form-control-danger"*/}
                                                                       {/*placeholder="iOS_Test"/>*/}
                                                                <AutoComplete
                                                                    options={platformAutoComplete}
                                                                    style={{
                                                                        display: 'block',
                                                                        width: '100%',
                                                                        fontSize: '1rem',
                                                                        lineHeight: '1.5',
                                                                    }}
                                                                    id="new_source_platform_input"
                                                                    onSearch={onSearchPlatformAutoComplete}
                                                                    placeholder="iOS_Test"
                                                                />
                                                                <small className="form-control-feedback">
                                                                    配置来源平台
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group has-success">
                                                                <label htmlFor="new_market_select"
                                                                       className="control-label">
                                                                    Market
                                                                </label>
                                                                <select id="new_market_select"
                                                                        className="form-control custom-select">
                                                                    <option value>
                                                                        -- 请选择Market --
                                                                    </option>
                                                                    <option value="none">
                                                                        none
                                                                    </option>
                                                                    <option value="iOS">
                                                                        iOS
                                                                    </option>
                                                                    <option value="Android">
                                                                        Android
                                                                    </option>
                                                                    <option value="Web">
                                                                        Web
                                                                    </option>
                                                                </select>
                                                                <small className="form-control-feedback">
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr/>
                                                </div>
                                            </form>
                                            <div className="row justify-content-center">
                                                <div>
                                                    <button onClick={addPlatformBtn.bind(this)} type="submit"
                                                            className="btn btn-info"
                                                            style={{width: '200px', height: '40px'}}>
                                                        <i className="fa fa-check">
                                                        </i>
                                                        OK
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-20" id="batch_copy_pane" role="tabpanel">
                            <div className="row">
                                <div className="col-2">
                                    <h3>
                                        From platform
                                    </h3>
                                    {/*<input id="from_platform_name" className="form-control input-rounded" style={{*/}
                                        {/*height: '30px',*/}
                                        {/*width: '170px',*/}
                                        {/*fontSize: 'small',*/}
                                        {/*marginBottom: '5px'*/}
                                    {/*}}/>*/}
                                    <AutoComplete
                                        options={platformAutoComplete}
                                        style={{
                                            width: 200,
                                        }}
                                        id="from_platform_name"
                                        onSearch={onSearchPlatformAutoComplete}
                                    />
                                    <button id="copy_refresh_btn" className="btn btn-info btn-flat m-b-10 "
                                            type="button" style={{width: '40px', height: '40px'}}
                                            onClick={copyRefresh.bind(this)}>
                                        <i className="fa fa-refresh">
                                        </i>
                                    </button>
                                    <hr style={{width: '170px'}}/>
                                    <label>
                                        Config Type
                                    </label>
                                    <select id="config_type" className="form-control custom-select">
                                        <option value>
                                            -- 请选择Config Type --
                                        </option>
                                        <option value="client">
                                            Client
                                        </option>
                                        <option value="common">
                                            Common
                                        </option>
                                        <option value="server">
                                            Server
                                        </option>
                                        <option value="all">
                                            All
                                        </option>
                                    </select>
                                    <label>
                                        Product Id Prefix
                                    </label>
                                    <input id="product_id_prefix" className="form-control input-rounded" style={{
                                        height: '30px',
                                        width: '170px',
                                        fontSize: 'small',
                                        marginBottom: '5px'
                                    }}/>
                                </div>
                                <div className="col-3">
                                    <h3>
                                        Config list
                                    </h3>
                                    <ul id="setting_checkbox_list" aria-expanded="true">
                                    </ul>
                                </div>
                                <div className="col-2" style={{marginTop: '50px'}}>
                                    <div id="submit_btn">
                                        <i className="fa fa-clone" style={{fontSize: '80px', marginRight: '10px'}}>
                                        </i>
                                        <i className="fa fa-arrow-right" style={{fontSize: '80px'}}>
                                        </i>
                                    </div>
                                    <p id="checked_config_list">
                                    </p>
                                </div>
                                <div className="col-3">
                                    <h3>
                                        Target platform
                                    </h3>
                                    {/*<input id="target_platform_name" className="form-control input-rounded" style={{*/}
                                        {/*height: '30px',*/}
                                        {/*width: '170px',*/}
                                        {/*fontSize: 'small',*/}
                                        {/*marginBottom: '5px'*/}
                                    {/*}}/>*/}
                                    <AutoComplete
                                        options={platformAutoComplete}
                                        style={{
                                            width: 200,
                                        }}
                                        id="target_platform_name"
                                        onSearch={onSearchPlatformAutoComplete}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <button className="btn btn-info" onClick={copyPlatform.bind(this)} style={{
                                    width: '200px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: '100px'
                                }}>
                                    复制
                                </button>
                            </div>
                        </div>
                        <div className="tab-pane p-20" id="op_vip_pane" role="tabpanel">
                            <div className="row justify-content-center">
                                <div className="col-9">
                                    <div className="card card-outline-info">
                                        <div className="card-header">
                                            <h4 className="m-b-0 text-white">
                                                设置或删除VIP
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <form action="#">
                                                <div className="form-body">
                                                    <hr/>
                                                    <div className="row p-t-20">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Platform
                                                                </label>
                                                                <input type="text" id="platformName"
                                                                       className="form-control" placeholder="iOS_Test"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group has-danger">
                                                                <label className="control-label">
                                                                    UserID
                                                                </label>
                                                                <input type="text" id="userId"
                                                                       className="form-control form-control-danger"
                                                                       placeholder=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group has-success">
                                                                <label htmlFor="new_market_select"
                                                                       className="control-label">
                                                                    操作类型
                                                                </label>
                                                                <select id="vip_op_select"
                                                                        className="form-control custom-select">
                                                                    <option value="add">
                                                                        增加
                                                                    </option>
                                                                    <option value="del">
                                                                        删除
                                                                    </option>
                                                                </select>
                                                                <small className="form-control-feedback">
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row justify-content-center">
                                                <div>
                                                    <button onClick={opVipBtn.bind(this)} type="submit" className="btn btn-info"
                                                            style={{width: '200px', height: '40px'}}>
                                                        <i className="fa fa-check">
                                                        </i>
                                                        OK
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-20" id="op_diff_two_platform" role="tabpanel">
                            <div id="config-col" className="col">
                                <div className="inbox-leftbar" style={{width: '22%'}}>
                                    <ul className="nav nav-tabs customtab2" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active show" data-toggle="tab" role="tab"
                                               aria-selected="true" onClick={diffTabChange.bind(this, 'client')}>
                                                    <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                                      Client
                                                    </span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link show" data-toggle="tab" role="tab"
                                               aria-selected="true" onClick={diffTabChange.bind(this, 'common')}>
                                                    <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                                      Common
                                                    </span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link show" data-toggle="tab" role="tab"
                                               aria-selected="true" onClick={diffTabChange.bind(this, 'server')}>
                                                    <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                                      Server
                                                    </span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="mail-list mt-4" style={{
                                        borderRadius: '.9rem',
                                        background: 'white',
                                        position: 'relative',
                                        top: '-18px'
                                    }}>
                                        <div id="twoConfigList"
                                             style={{position: 'relative', textAlign: 'center', minHeight: '400px'}}>
                                            {(() => {
                                                return twoCofnigList.map((key) => {
                                                    return (
                                                        <li
                                                            id={key}
                                                            onClick={showConfigDiff.bind(this, key)}
                                                            style={{height: '35px'}}>
                                                            <a style={{fontSize: 'small', color: 'black'}}>
                                                                {key}
                                                            </a>
                                                        </li>
                                                    );
                                                });
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xlg-8 inbox-rightbar" style={{marginLeft: '30%', minHeight: '400px'}}>
                                <div className="col">
                                    {/*<input id="first_platform_input" type="text" className="form-control input-rounded"*/}
                                           {/*placeholder="platform 1" style={{*/}
                                        {/*height: '30px',*/}
                                        {/*width: '200px',*/}
                                        {/*fontSize: 'small',*/}
                                        {/*position: 'relative',*/}
                                        {/*left: '-15%'*/}
                                    {/*}} onChange={checkInputValue.bind(this)}/>*/}
                                    <AutoComplete
                                        options={platformAutoComplete}
                                        style={{
                                            width: 200,
                                            marginLeft: '-120px',
                                            marginRight: '50px'
                                        }}
                                        id="first_platform_input"
                                        onSearch={onSearchPlatformAutoComplete}
                                        placeholder="platform 1"
                                    />
                                    {/*<input id="second_platform_input" type="text" className="form-control input-rounded"*/}
                                           {/*placeholder="platform 2" style={{*/}
                                        {/*height: '30px',*/}
                                        {/*width: '200px',*/}
                                        {/*fontSize: 'small',*/}
                                        {/*position: 'relative',*/}
                                        {/*left: '-5%'*/}
                                    {/*}} onChange={checkInputValue.bind(this)}/>*/}
                                    <AutoComplete
                                        options={platformAutoComplete}
                                        style={{
                                            width: 200,
                                        }}
                                        id="second_platform_input"
                                        onSearch={onSearchPlatformAutoComplete}
                                        placeholder="platform 2"
                                    />
                                    <button
                                        id="btn_refresh"
                                        className="btn btn-info btn-flat m-b-10 "
                                        type="button"
                                        style={{
                                        width: '40px',
                                        height: '40px',
                                        position: 'absolute',
                                        left: '400px'
                                    }} onClick={checkInputValue.bind(this)}>
                                        <i className="fa fa-refresh">
                                        </i>
                                    </button>
                                    <Button type="primary" style={{marginLeft: '150px', height: '40px'}} onClick={() => {setSplitView(!splitView)}}>
                                        切换显示模式
                                    </Button>
                                </div>
                                <div className="card" style={{
                                    width: '100%',
                                    minHeight: '380px',
                                    position: 'relative',
                                    left: '-13%',
                                    overflowX: 'auto'
                                }}>
                                    <div id="two_platform_config_diff">
                                        <ReactDiffViewer
                                            oldValue={platform1ConfigValue}
                                            newValue={platform2ConfigValue}
                                            splitView={splitView}
                                            leftTitle={Platform1}
                                            rightTitle={Platform2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-20" id="op_iap_check" role="tabpanel"
                             style={{overflowX: 'hidden', overflowY: 'hidden'}}>
                            <div className="col">
                                <div className="row" style={{width: '70%', marginLeft: '20px'}}>
                                    <div className="card" style={{width: '100%', height: '80px', position: 'relative'}}>
                                        <div className="row">
                                            {/*<Input id="iap_check_platform_input" type="text"*/}
                                                   {/*className="form-control input-rounded" placeholder="platform name"*/}
                                                   {/*style={{*/}
                                                       {/*height: '30px',*/}
                                                       {/*width: '200px',*/}
                                                       {/*fontSize: 'small',*/}
                                                       {/*position: 'relative',*/}
                                                       {/*left: '20px'*/}
                                                   {/*}} onChange={setOriginIapCheck.bind(this)} />*/}
                                            <AutoComplete
                                                options={iapCheckPlatformAutoComplete}
                                                style={{
                                                    width: 200,
                                                    height: '30px',
                                                    left: 20
                                                }}
                                                id="iap_check_platform_input"
                                                onSearch={onSearchIapCheckPlatformAutoComplete}
                                                placeholder="platform name"
                                                onChange={setOriginIapCheck.bind(this)}
                                            />
                                            <input className="input-flat" type="button" defaultValue="check" style={{
                                                width: '60px',
                                                height: '30px',
                                                position: 'absolute',
                                                left: '250px',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                verticalAlign: 'middle',
                                                lineHeight: '25px'
                                            }} onClick={refreshIapCheck.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: '20px'}}>
                                    <div className="card" style={{width: '100%', position: 'relative'}}>
                                        <div>
                                            <CodeMirror
                                                id="iap_check_value"
                                                className="config_content"
                                                ref={iapCheckEditorRef}
                                                value=''
                                                options={{
                                                    mode: 'application/ld+json',
                                                    lineNumbers: true
                                                }}
                                                onChange={(editor, data, value) => {
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-20" id="op_iap_order_check" role="tabpanel"
                             style={{overflowX: 'hidden', overflowY: 'hidden'}}>
                            <div className="col">
                                <div className="row" style={{width: '70%', marginLeft: '20px'}}>
                                    <div className="card"
                                         style={{width: '100%', height: '200px', position: 'relative'}}>
                                        <div className="row">
                                            <Input id="iap_order_check_platform_input" type="text"
                                                   className="form-control input-rounded" placeholder="platform"
                                                   style={{
                                                       height: '30px',
                                                       width: '200px',
                                                       fontSize: 'small',
                                                       position: 'relative',
                                                       left: '20px'
                                                   }}/><br/>
                                            <p>&nbsp;</p>
                                            <Input id="receipt_value" type="text" className="form-control input-rounded"
                                                   placeholder="receipt" style={{
                                                height: '60px',
                                                width: '800px',
                                                fontSize: 'small',
                                                position: 'relative',
                                                left: '20px'
                                            }}/><br/>
                                        </div>
                                        <br/>
                                        <input className="input-flat" type="button" defaultValue="check"
                                               style={{width: '60px', height: '30px', left: '250px'}}
                                               onClick={iapOrderCheck.bind(this)} />
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: '20px'}}>
                                    <div className="card" style={{width: '100%', position: 'relative'}}>
                                        <div>
                                            <textarea id="check_result" style={{
                                                width: '100%',
                                                minHeight: '400px',
                                                resize: 'vertical',
                                                overflowY: 'auto'
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="text_diff_modal" className="modal hide">
                <div className="modal-header">
                    <a>
                    </a>
                    <h3>
                        文本比较
                        <button type="button" className="btn btn-danger" style={{float:'right', position: 'relative', left:'80%'}}
                                onClick={() => {
                                    $('#text_diff_modal').modal('toggle');
                                    $('#comfirm_btn').remove();
                                }}
                        >
                            关闭
                        </button>
                        <button type="button" className="btn btn-default"
                                style={{float:'right', position: 'relative', left:'75%', color: '#000'}}
                                onClick={() => {setSplitView1(false)}}>
                            在一栏中比较
                        </button>
                        <button type="button" id="last" className="btn btn-default"
                                style={{float:'right', position: 'relative', left: '70%', color: '#000'}}
                                onClick={() => {setSplitView1(true)}}>
                            分二栏比较
                        </button>
                    </h3>
                </div>
                <div className="modal-body">
                    <div id="diffoutput">
                        <ReactDiffViewer
                            oldValue={preConfigValue}
                            newValue={currentConfigValue}
                            splitView={splitView1}
                            leftTitle="修改前"
                            rightTitle="修改后"
                        />
                    </div>
                </div>
            </div>
            <div id="copy_diff_modal" className="modal hide">
                <div className="modal-header">
                    <a>
                    </a>
                    <h3>
                        <select id="diff_config" className="form-control custom-select" style={{width: "45%"}}>
                            <option value="">
                                请选择Config
                            </option>
                        </select>
                        <select id="diff_platform" className="form-control custom-select" style={{width: "45%"}}>
                            <option value="">
                                请选择Platform
                            </option>
                        </select>
                        <button type="button" className="btn btn-danger" style={{float: 'right', position: 'relative', left: '80%'}}
                                onClick={() => {$('#copy_diff_modal').modal('toggle');}}>
                            关闭
                        </button>
                        <button type="button" onClick={() => {}} className="btn btn-info"
                                style={{float:'right', position: 'relative', left:'65%'}}>
                            确认保存
                        </button>
                    </h3>
                </div>
                <div className="modal-body">
                    <div id="diffoutput_copy">
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Setting;
