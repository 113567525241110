import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import ReactDiffViewer from 'react-diff-viewer';

const ResourcePreview = () => {
    const username = localStorage.getItem("username");
    const menuId = $("#menu_id").html();
    const [oldCode, setOldCode] = useState('');
    const [newCode, setNewCode] = useState('');

    function getQueryParam(variable){
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return false;
    }

    function onConfirm() {
        const resourceName = getQueryParam('resource');
        const version = getQueryParam('version');

        if (!resourceName || !version) {
            alert('param invalid: resource_name/version');
            return;
        }

        fetch('/api/v1/resource/release?username=' + username + '&menuId=' + menuId, {
            method: 'post',
            body:    JSON.stringify({resource_name: resourceName.toLowerCase(), version}),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                return response.json();
            })
            .then((updateRs) => {
                console.log(updateRs);
                if (updateRs.err) {
                    alert(updateRs.err);
                    return;
                }
                if (updateRs.data.status === 'success') {
                    alert('success');
                }
            })
            .catch((e) => {
                alert('confirm failed: ' + e.message);
            });
    }

    useEffect(async () => {
        (async() => {
            const resourceName = getQueryParam('resource');
            const version = getQueryParam('version');
            if (!resourceName || !version) {
                return;
            }

            const dataResponse = await fetch(`/api/v1/resource/preview?resource_name=${resourceName}&version=${version}`);
            const data = await dataResponse.json();

            if (data.err) {
                alert(data.err);
                return;
            }

            setOldCode(JSON.stringify(data.data.releaseList, null, 2));
            setNewCode(JSON.stringify(data.data.list, null, 2));
        })()
    }, []);

    return (
        <div className="page-wrapper">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">个性化资源管理</h3>
                </div>
                <div className="col-md-7 align-self-center">
                </div>
            </div>
            <div className="container-fluid">
                <div className="card" id="card">
                    <div id="submit" style={{marginBottom: 20}}>
                        <Button type="primary" htmlType="submit" onClick={onConfirm}>
                            发布
                        </Button>
                    </div>
                    <div id="diff-content">
                        <ReactDiffViewer
                            oldValue={oldCode}
                            newValue={newCode}
                            splitView={true}
                            leftTitle="线上配置"
                            rightTitle="当前配置"
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ResourcePreview;

