import React, { Component } from 'react'
class SpecialSetting extends Component {
    componentDidMount() {
        // 在组件挂载后执行自动跳转
        const newWindow = window.open('', '_blank');
        newWindow.location.href = '/different_config'; // 替换为你要跳转的 URL
    }

    render() {
        return (
            <div>即将跳转</div>
        );
    }
}
export default SpecialSetting
