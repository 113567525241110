import React from 'react';

export default class Index extends React.Component {
    render() {
        return (
            <iframe
                style={{width:'95%', height:'800px', overflow:'visible', margin:'50px'}}
                ref="iframe"
                src="/s3/index.html"
                scrolling="auto"
                frameBorder="0"
            />
        );
    }
}
