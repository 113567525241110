import React, { useState, useEffect } from 'react';
import { Menu, Input, Button, Tree, Select, Spin } from 'antd';

import './Resource.scss';

let RESOURCE_NAME = '';
let VERSION_NAME = 'gate';
let SELECT_DATA = '';

let tree = [];
let showList = [];

const Resource = () => {
    const username = localStorage.getItem("username");
    const menuId = $("#menu_id").html();

    const [resourceList, setResourceList] = useState([]);
    const [fileLoadingFlag, setFileLoadingFlag] = useState(false);

    const [expandedKeys, setExpandedKeys] = React.useState([]);
    const [checkedKeys, setCheckedKeys] = React.useState([]);
    const [selectedKeys, setSelectedKeys] = React.useState([]);
    const [autoExpandParent, setAutoExpandParent] = React.useState(true);
    const [treeList, setTree] = React.useState(tree);

    async function reloadFileContent () {
        tree = [];
        showList = [];
        $('.card #item-content textarea').val('');
        if (RESOURCE_NAME && VERSION_NAME) {
            setFileLoadingFlag(true);
            const response = await fetch(`/api/v1/resource/files?resource_name=${RESOURCE_NAME.toLowerCase()}&version=${VERSION_NAME}`);
            let rs = await response.json();
            rs = rs.data;
            tree = rs.fileList;
            showList = rs.showList;
            setTree(tree);
            setCheckedKeys(showList);
            setFileLoadingFlag(false);
        }
    }

    const onTreeExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onTreeCheck = async (checkedKeysValue, e) => {
        // console.log('onCheck', checkedKeysValue);
        setCheckedKeys(checkedKeysValue);
        const checked = e.checked;
        const node = e.node;
        await fetch('/api/v1/resource/showstate?username=' + username + '&menuId=' + menuId, {
            method: 'post',
            body:    JSON.stringify({checked, resource_name: RESOURCE_NAME, version: VERSION_NAME, node_info: node}),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                return response.json();
            })
            .then((changeRs) => {
                if (changeRs.err) {
                    alert(changeRs.err);
                }
            })
            .catch((e) => {
                alert('change status failed');
            });
    };

    const onTreeSelect = (selectedKeysValue, info) => {
        // console.log('onSelect', info);
        SELECT_DATA = info.node.key;
        setSelectedKeys(selectedKeysValue);
        const itemContentSelector = $('.card #item-content textarea');
        itemContentSelector.val('');
        console.log(RESOURCE_NAME);
        console.log(VERSION_NAME);
        fetch(`/api/v1/resource/data?username=${username}&menuId=${menuId}`, {
            method: 'post',
            body:    JSON.stringify({resource_name: RESOURCE_NAME.toLowerCase(), version: VERSION_NAME, key: info.node.key}),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.err) {
                    alert(data.err)
                }
                const resourceInfo = data.data.resourceInfo;
                try {
                    const extData = JSON.parse(resourceInfo.data);
                    itemContentSelector.val(JSON.stringify(extData, null, 2));
                } catch (e) {
                    //
                }
            })
            .catch((e) => {
                alert(e.message);
            })
    };

    async function onTreeDrop (info) {
        console.log(info);
        console.log(info.dropPosition);
        function find (arr, key) {
            let result = null;
            for (const item of arr) {
                if (item.key === key) {
                    result = item;
                    return result;
                }
                if (Array.isArray(item.children)) {
                    result = find(item.children, key);
                    if (result) {
                        return result;
                    }
                }
            }
            return result;
        }

        const dragNode = info.dragNode;
        const node = info.node;
        if (dragNode.parent !== node.parent) {
            return;
        }

        const parentItem = dragNode.parent !== '' ? find(tree, dragNode.parent) : {children: tree};
        const currentItem = find(tree, dragNode.key);
        if (!parentItem || !currentItem) {
            return;
        }
        let currentIndex = -1;
        const targetIndex = info.dropPosition < 0 ? 0 : info.dropPosition;
        parentItem.children.forEach((item, index) => {
            if (item.key === currentItem.key) {
                currentIndex = index;
            }
        });

        if (targetIndex >= 0) {
            const gapItem = targetIndex - 1 < 0 ? null : parentItem.children[targetIndex - 1];
            const headItem = (parentItem.children.map((item) => {
                if (item.prev_path === 'empty') {
                    return item;
                }
            })
                .filter((item) => {
                    return item;
                }))[0] || parentItem.children[0];

            await fetch('/api/v1/resource/sort?username=' + username + '&menuId=' + menuId, {
                method: 'post',
                body:    JSON.stringify({
                    resource_name: RESOURCE_NAME.toLowerCase(),
                    version: VERSION_NAME,
                    drop_item: currentItem,
                    gap_item: gapItem,
                    head_item: headItem
                }),
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    return response.json();
                })
                .then((updateRs) => {
                    // console.log(updateRs);
                    if (updateRs.err) {
                        alert(updateRs.err);
                    }
                    if (updateRs.data.status === 'success') {
                        //
                        parentItem.children[currentIndex] = null;
                        parentItem.children.splice(targetIndex, 0, JSON.parse(JSON.stringify(currentItem)));
                        parentItem.children = parentItem.children.filter((item) => {
                            return item;
                        });
                        tree = tree.filter((item) => {
                            return item;
                        });

                        setTree(JSON.parse(JSON.stringify(tree)));
                        alert('success');
                    }
                })
                .catch((e) => {
                    alert('change sort failed');
                });
        }
    }

    async function initNav () {
        const resourceResponse = await fetch('/api/v1/resource/resources?username=' + username + '&menuId=' + menuId);
        let resourceData = await resourceResponse.json();
        if (resourceData.err) {
            alert(resourceData.err);
            return;
        }

        const resourceList = resourceData.data.resourceList;
        setResourceList(resourceList);
    }

    const itemContentOnFinish = () => {
        const itemdata = $('.card #item-content textarea').val();

        if (!SELECT_DATA) {
            alert('select item first');
            return;
        }
        let extData = {};
        try {
            extData = JSON.parse(itemdata);
        } catch (e) {
            alert('content is not json');
            return;
        }
        fetch('/api/v1/resource/update?username=' + username + '&menuId=' + menuId, {
            method: 'post',
            body:    JSON.stringify({resource_name: RESOURCE_NAME.toLowerCase(), version: VERSION_NAME, key: SELECT_DATA, ext_data: extData}),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                return response.json();
            })
            .then((updateRs) => {
                // console.log(updateRs);
                if (updateRs.err) {
                    alert(updateRs.err);
                }
                if (updateRs.data.status === 'success') {
                    alert('success');
                }
            })
            .catch((e) => {
                alert('change status failed');
            });
    };

    function resourceChange ({ item, key, keyPath, domEvent }) {
        if (key !== RESOURCE_NAME) {
            RESOURCE_NAME = key;
            reloadFileContent();
        }
    }

    function versionChange (key) {
        if (key !== VERSION_NAME) {
            VERSION_NAME = key;
            reloadFileContent();
        }
    }

    useEffect( () => {
        initNav();
        reloadFileContent([]);
    }, []);

    return (
        <div id="resource-component">
            <div className="page-wrapper" style={{overflowAnchor: 'none', marginTop: '20px'}}>
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-primary">个性化资源管理</h3>
                    </div>
                    <div className="col-md-7 align-self-center">
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card" id="card" style={{display: 'inline-box'}}>
                        <div className="my-container">
                            <div className="main">
                                <div id="operate">
                                    <div id="operate-preview" style={{display: 'inline-block', marginLeft: 20}}>
                                        <Button type="primary" htmlType="submit" onClick={() => {
                                            if (RESOURCE_NAME && VERSION_NAME) {
                                                window.open(`/page/resource_preview.html?resource=${RESOURCE_NAME}&version=${VERSION_NAME}`, '_blank');
                                            }
                                        }}>
                                            预览
                                        </Button>
                                    </div>
                                    <div id="operate-rollback" style={{display: 'inline-block', marginLeft: 20}}>
                                        <Button type="primary" htmlType="submit" onClick={() => {
                                            if (RESOURCE_NAME && VERSION_NAME) {
                                                window.open(`/page/resource_rollback.html?resource=${RESOURCE_NAME}&version=${VERSION_NAME}`, '_blank');
                                            }
                                        }}>
                                            内容回滚
                                        </Button>
                                    </div>
                                </div>
                                <div id="file-content">
                                    <p>以下树形控件: 1.勾选则在客户端接口中显示, 不勾则隐藏; 2. 点击单一节点以编辑其属性; 3. 拖拽节点调整顺序</p>
                                    <Tree
                                        checkable
                                        showLine={true}
                                        draggable={true}
                                        onExpand={onTreeExpand}
                                        expandedKeys={expandedKeys}
                                        autoExpandParent={autoExpandParent}
                                        onCheck={onTreeCheck}
                                        checkedKeys={checkedKeys}
                                        onSelect={onTreeSelect}
                                        selectedKeys={selectedKeys}
                                        onDrop={onTreeDrop}
                                        treeData={treeList}
                                    />
                                </div>
                            </div>
                            <div className="left">
                                <div id="nav">
                                    <div>
                                        <p>资源名称</p>
                                        <Menu
                                            style={{ width: 256 }}
                                            defaultSelectedKeys={[]}
                                            defaultOpenKeys={[]}
                                            onClick={resourceChange}
                                        >
                                            {
                                                (() => {
                                                    return resourceList.map((k) => {
                                                        return (<Menu.Item key={k}>{k}</Menu.Item>)
                                                    })
                                                })()
                                            }
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div id="select-version">
                                    <Select
                                        style={{ width: 200 }}
                                        placeholder="Select a version"
                                        optionFilterProp="children"
                                        defaultValue="gate"
                                        onSelect={versionChange}
                                    >
                                        <Select.Option value="crazy_ads">crazy_ads</Select.Option>
                                        <Select.Option value="crazy_vip">crazy_vip</Select.Option>
                                        <Select.Option value="normal_ads">normal_ads</Select.Option>
                                        <Select.Option value="normal_vip">normal_vip</Select.Option>
                                        <Select.Option value="gate">gate</Select.Option>
                                    </Select>
                                </div>
                                <div id="item-content">
                                    <Input.TextArea rows={6} />
                                    <p> </p>
                                    <Button type="primary" htmlType="submit" onClick={itemContentOnFinish}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                            <div id="loading">
                                <Spin spinning={fileLoadingFlag} tip="Loading...">

                                </Spin>,
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resource;

