import React, { useState, useRef, useEffect } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/selection/active-line';
import ReactDiffViewer from 'react-diff-viewer';

// import {
//     addConfigBtn, refresh, save, format, diffUsingJS,
//     database_config_content, showConfigItem,
// } from '../../js/dashuaibi';

import './Dashuaibi.scss';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import {Button} from "antd";

let tolConfig = {};
let curType = 'task';
let curKey = '';
let database_config_content = '';

const Dashuaibi = () => {
    const codeEditorRef = useRef();

    const [configListData, setConfigListData] = useState([]);

    const [preConfigValue, setPreConfigValue] = useState('');
    const [currentConfigValue, setCurrentConfigValue] = useState('');
    const [splitView, setSplitView] = useState(true);

    /**
     * 点击菜单中的某个key，展现某一条key的value
     * 并更新curKey
     * @param {*} key
     */
    async function showConfigItem(key, codeEditorRef) {
        const editor = codeEditorRef.current.editor;
        $('#'+key).addClass("clicked").siblings().removeClass("clicked");
        $('#config_item_lable').empty();
        $("#config_item_lable").html("Start get config ...");
        curKey = key;
        let content = await querySingle(curKey);
        $('#config_item_lable').html(curKey);
        if(typeof content === 'object') {
            content = JSON.stringify(content);
        }
        content = formatJson(content);
        database_config_content = content;
        editor.setValue(content);
    }


    /**
     * 点击更新按键时，更新菜单
     * + 更新输入框中的内容
     */
    async function refresh() {
        queryAll(curType);
        if (curKey) {
            showConfigItem(curKey, codeEditorRef);
        }
    }

    /**
     * 点击保存时，保存输入框中内容
     */
    async function save() {
        const editor = codeEditorRef.current.editor;
        let configValue = editor.getValue();
        if(!curKey) {
            alert('缺少Key');
            return;
        }
        if(!configValue) {
            alert('缺少Value');
            return;
        }
        let r = confirm("确认输入内容无误，继续保存吗？");
        if(!r) return;

        configValue = formatJson(configValue);

        try {
            configValue = JSON.parse(configValue);
        } catch (e) {
            configValue = configValue.trim();
        }
        if (typeof(configValue) == 'object') {
            configValue = JSON.stringify(configValue, null, 4);
        }

        updateConfig(curKey, configValue);
    }

    /**
     * 点击增加的按键时，经过用户确认，就会新建一个value为空的key
     */
    async function addConfigBtn() {
        let configKey = $('#newConfigItem').val();
        if(!configKey) {
            alert('请输入新的config名称');
            return;
        }
        let r = confirm("确认新建" + configKey + "吗？");
        if(!r) return;
        await addConfig(configKey);
        queryAll(curType);
        showConfigItem(configKey, codeEditorRef);
    }

    /**
     * 点击格式化时，将输入框中的json格式化
     */
    async function format() {
        const editor = codeEditorRef.current.editor;
        let content = editor.getValue();
        let result = formatJson(content);

        if (result) {
            editor.setValue(result);
        }
    }

    /**
     * 获得某个configtype下的单个配置
     * 并且更新菜单
     * @param {*} settingName
     */
    async function querySingle(settingName) {
        return new Promise(async (resolve, reject) => {
            if(!settingName) {
                alert('未选中配置');
                return;
            }
            let username = localStorage.getItem('username');
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: 'GET',
                url: '/api/v1/config/server?setting_name=' + settingName + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if(data.isReload) {
                            window.location.href = '/login.html';
                            return;
                        }
                        alert('Query Failed：' + data.err);
                        reject(data.err);
                    } else {
                        resolve(data.data);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("查询配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                }
            });
        });
    }

    /**
     * 更新config的内容
     * @param {*} configKey
     * @param {*} configValue
     */
    async function updateConfig(configKey, configValue) {
        return new Promise(async (resolve, reject) => {
            let username = localStorage.getItem('username');
            $.ajax({
                type: 'POST',
                url: '/api/v1/config/update_server_config?username=' + username + '&menuId=dashuaibi',
                data: {
                    setting_name: configKey,
                    setting_value: configValue,
                },
                success: function(data) {
                    if (!data || data.err) {
                        if(data.isReload) {
                            window.location.href = '/login.html';
                            return;
                        }
                        alert('提交失败：' + data.err);
                        reject(data.err);
                    } else {
                        alert("Change Success.");
                        resolve();
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("更新配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                }
            });
        });
    }

    /**
     *
     * @param {*} configKey
     */
    async function addConfig(configKey) {
        return new Promise(async (resolve, reject) => {
            let username = localStorage.getItem('username');
            $.ajax({
                type: 'POST',
                url: '/api/v1/config/server?username=' + username + '&menuId=dashuaibi',
                data: {
                    setting_name: configKey,
                    setting_value: '',
                    server_config_type: curType
                },
                success: function(data) {
                    if (!data || data.err) {
                        if(data.isReload) {
                            window.location.href = '/login.html';
                            return;
                        }
                        alert('提交失败：' + data.err);
                        reject(data.err);
                    } else {
                        alert("Change Success.");
                        resolve();
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("新增配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                }
            });
        });
    }

/////////////////////////////////////////////////////////////////////////////////////
// controller函数
/////////////////////////////////////////////////////////////////////////////////////

    /**
     * 输入一个数组和需要加入列表的id，直接生成菜单栏
     * @param {*} list
     * @param {*} id
     */
    async function buildMenu(list, id, setConfigListData) {
        // $('#'+id).empty();
        // let len = list.length;
        // let html = '';
        // for(let i = 0; i < len; i++) {
        //     let key = list[i];
        //     html = '<li id="' + key + '" onclick="showConfigItem(\'' + key + '\')" style="height: 35px"><a href="#" style="font-size: small;color: black;">' + key + '</a></li>'
        //     $('#'+id).append(html)
        // }
        setConfigListData(list);
    }

    /**
     * 格式化JSON
     */
    function formatJson(content) {
        if (!content) {
            return '';
        }
        try {
            content = JSON.parse(content);
        } catch (e) {
            content = content.replace(/\s/g,"");
            try{
                const objJsonParsed = content.includes('{') && content.includes('}');
                const arrayJsonParsed = content.includes('[') && content.includes(']');
                const numberJsonParsed = content.match(new RegExp(/^[0-9]*$/));
                if (objJsonParsed || arrayJsonParsed || numberJsonParsed) {
                    content = JSON.parse(content);
                }
            } catch (e) {
                alert(e);
                return
            }
        }
        if (typeof(content) == 'object') {
            content = JSON.stringify(content, null, 4);
        }
        if (typeof (content) === 'number') {
            content = content.toString();
        }
        return content;
    }

    function diffUsingJS(old_content,codeEditorRef,display_diff_div_obj,view_type) {
        const new_content = codeEditorRef.current.editor.getValue();
        setPreConfigValue(old_content || '');
        setCurrentConfigValue(new_content);
        setSplitView(!view_type);
    }

    /**
     * 菜单栏选中悬停效果
     */
    $("li").click(function(){
        $(this).addClass("clicked").siblings().removeClass("clicked");
    });

    $("#btn_check").click(function() {
        // let content = editor.getValue();
        // alert('格式正确');
        diffUsingJS(database_config_content, codeEditorRef, $("#diffoutput"),0);
        $('#text_diff_modal').css({
            "width":"100%",
            // "margin-left":"-50%",
            "height":"95%",
            "margin-top":"-70px",
            "background-color":"white",
            "top":"90px",
            "position":"fixed",
            "overflow-x":"auto"
        });
        $('h3').css({
            "position":"relative",
            "left":"-30%"
        })
        $('#text_diff_modal div.modal-body').css({
            "max-height":"100%",
            "padding-bottom":"20px"
        });
        $("#diffoutput").css({
            "max-height":window.screen.availHeight-120+"px"
        });
        $('#text_diff_modal').modal();
    });

    async function tabChange(type) {
        const editor = codeEditorRef.current.editor;
        // $('#configList').empty();
        setConfigListData([]);
        $('#config_item_lable').empty();
        editor.setValue('');
        curType = type;
        curKey = '';
        queryAll(curType);
    }

    async function queryAll(configType) {
        if(!configType) {
            alert('缺少Type');
            return;
        }
        let username = localStorage.getItem('username');
        let menuId = $("#menu_id").html();
        // 获得所有config
        $.ajax({
            type: 'GET',
            url: '/api/v1/config/server_config_names?server_config_type=' + configType + '&username=' + username + '&menuId=' + menuId,
            success: function(data) {
                if (!data || data.err) {
                    if(data.isReload) {
                        window.location.href = '/login.html';
                        return;
                    }
                    alert('Query Failed：' + data.err);
                    return;
                } else {
                    // 获得所有key组成的数组
                    let allKey = data.data;
                    setConfigListData(allKey);
                    // $('#'+ curKey).addClass("clicked").siblings().removeClass("clicked");
                }
            },
            error: function(req, status, err) {
                const message = err.message || err;
                alert("查询配置失败，网络出问题了，请稍后重试。错误原因:" + message);
            }
        });

    }

    useEffect(() => {
        $(".CodeMirror-scroll").hover(function(){
            $(this).get(0).style.cursor = "text";
        });
        codeEditorRef.current.editor.setSize('auto', 'auto');
        tabChange('task');
    }, []);

    return (
        <div id="dashuaibi-component">
            <div className="page-wrapper" style={{overflowAnchor: 'none', marginTop: '20px'}}>
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-info">
                            Config
                        </h3>
                    </div>
                    <div className="col-md-7 align-self-center">
                    </div>
                </div>
                <div className="container-fluid" style={{overflowX: 'scroll'}}>
                    <ul className="nav nav-tabs customtab2" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active show" data-toggle="tab" href="#Pane" role="tab" aria-selected="true" onClick={tabChange.bind(this, 'task')}>
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  Task配置
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#Pane" role="tab" aria-selected="false" onClick={tabChange.bind(this, 'server_control')}>
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  服务器配置
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" style={{minWidth: 1296}}>
                        <div className="tab-pane active show" id="Pane" role="tabpanel" style={{overflowX: 'hidden'}}>
                            <div id="change-col" className="col">
                                <div className="inbox-leftbar" style={{marginTop: '24px'}}>
                                    <div>
                                        <input id="newConfigItem" type="text" className="form-control input-rounded" placeholder="新增config" style={{height: 30, width: 150, fontSize: 'small', position: 'relative'}} />
                                        <button className="btn-info " type="button" onClick={addConfigBtn.bind(this)} style={{position: 'relative', top: '-28px', left: '152px'}}>
                                            +
                                        </button>
                                    </div>
                                    <div className="mail-list mt-4" style={{borderRadius: '.9rem', background: 'white', position: 'relative', top: '-30px'}}>
                                        <div id="configList" style={{position: 'relative', top: 50, textAlign: 'center', minHeight: 300}}>
                                            {(() => {
                                                return configListData.map((item) => {
                                                    return (
                                                        <li
                                                            id={item}
                                                            key={item}
                                                            onClick={showConfigItem.bind(null, item, codeEditorRef)}
                                                            style={{height: '35px'}}
                                                        >
                                                            <a href="#" style={{fontSize: 'small', color: 'black'}}>{item}</a>
                                                        </li>
                                                    )
                                                })
                                            })()}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: 20}}>
                                    <div className="card" style={{width: '100%', height: 80, position: 'relative'}}>
                                        <div className="row">
                                            <p id="write_status" style={{position: 'relative', top: 10, left: 50, fontSize: 'small'}}>
                                                Config Item:
                                                <span id="config_item_lable">
                                                </span>
                                            </p>
                                            <button id="btn_refresh" className="btn btn-info btn-flat m-b-10 " type="button" style={{width: 40, height: 40, position: 'absolute', left: 550}} onClick={refresh.bind(this)}>
                                                <i className="fa fa-refresh" />
                                            </button>
                                            <button id="btn_submit" className="btn btn-info btn-flat m-b-10 " type="button" style={{width: 40, height: 40, position: 'absolute', left: 600}} onClick={save.bind(this)}>
                                                <i className="fa fa-save" />
                                            </button>
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: '70px',
                                                    height: '40px',
                                                    position: 'absolute',
                                                    left: '650px'
                                                }}
                                                onClick={format}
                                            >
                                                格式化
                                            </Button>
                                            <Button
                                                type="primary"
                                                id="btn_check"
                                                style={{
                                                    width: '60px',
                                                    height: '40px',
                                                    position: 'absolute',
                                                    left: '730px'
                                                }}
                                            >
                                                校验
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: 20}}>
                                    <div className="card" style={{width: '100%', position: 'relative'}}>
                                        <div>
                                            <CodeMirror
                                                id="code"
                                                className="config_content"
                                                style={{width: '100%', minHeight: 1400, resize: 'vertical', overflowY: 'auto'}}
                                                ref={codeEditorRef}
                                                value=''
                                                options={{
                                                    mode: 'application/ld+json',
                                                    lineNumbers: true,
                                                    indentUnit: 4
                                                }}
                                                onChange={(editor, data, value) => {
                                                    //
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="text_diff_modal" className="modal hide">
                <div className="modal-header">
                    <a />
                    <h3>
                        文本比较
                        <button type="button" className="btn btn-danger" style={{float: 'right', position: 'relative', left: '80%'}} onClick={() => {
                            $('#text_diff_modal').modal('toggle');
                            $('#comfirm_btn').remove();}}>
                            关闭
                        </button>
                        <button type="button" className="btn btn-default" style={{float: 'right', position: 'relative', left: '75%', color: '#000'}} onClick={() => {setSplitView(false)}}>
                            在一栏中比较
                        </button>
                        <button type="button" id="last" className="btn btn-default" style={{float: 'right', position: 'relative', left: '70%', color: '#000'}} onClick={() => {setSplitView(true)}}>
                            分二栏比较
                        </button>
                    </h3>
                </div>
                <div className="modal-body">
                    <div id="diffoutput">
                        <ReactDiffViewer
                            oldValue={preConfigValue}
                            newValue={currentConfigValue}
                            splitView={splitView}
                            leftTitle="修改前"
                            rightTitle="修改后"
                        />
                    </div>
                </div>
            </div>
            <div id="copy_diff_modal" className="modal hide">
                <div className="modal-header">
                    <a />
                    <h3>
                        <select id="diff_config" className="form-control custom-select" style={{width: '45%'}}>
                            <option value>请选择Config</option>
                        </select>
                        <select id="diff_platform" className="form-control custom-select" style={{width: '45%'}}>
                            <option value>请选择Platform</option>
                        </select>
                        <button type="button" className="btn btn-danger" style={{float: 'right', position: 'relative', left: '80%'}} onClick={() => {$('#copy_diff_modal').modal('toggle')}}>
                            关闭
                        </button>
                        <button type="button" onClick={() => {}} className="btn btn-info" style={{float: 'right', position: 'relative', left: '65%'}}> 确认保存</button>
                    </h3>
                </div>
                <div className="modal-body">
                    <div id="diffoutput_copy">
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Dashuaibi;
