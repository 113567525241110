import React from 'react';
// import Script from 'react-load-script';
import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import './App.css';

// import 'bootstrap';

import IRoute from './router';

function App() {
  return (
    <div className="App">
        <div className="preloader">
            <svg className="circular" viewBox="25 25 50 50">
                <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
        </div>
        <IRoute/>
        <span id="menu_id" hidden="hidden">setting</span>
    </div>
  );
}

export default App;
