import React, { useState, useEffect } from 'react';
import { Card, Button, Select, Spin } from 'antd';

let RELEASE = '';

const ResourceRollback = () => {
    const username = localStorage.getItem("username");
    const menuId = $("#menu_id").html();

    const [fileLoadingFlag, setFileLoadingFlag] = useState(false);
    const [releaseList, setReleaseList] = useState([]);
    const [content, setContent] = useState([]);

    function getQueryParam(variable){
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return false;
    }

    function onConfirm() {
        const resourceName = getQueryParam('resource');
        const version = getQueryParam('version');
        const release = RELEASE;

        if (!resourceName || !version) {
            alert('param invalid: resource_name/version');
            return;
        }

        fetch('/api/v1/resource/release/rollback?username=' + username + '&menuId=' + menuId, {
            method: 'post',
            body:    JSON.stringify({resource_name: resourceName.toLowerCase(), version, release}),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                return response.json();
            })
            .then((updateRs) => {
                if (updateRs.err) {
                    alert(updateRs.err);
                    return;
                }
                if (updateRs.data.status === 'success') {
                    alert('success');
                }
            })
            .catch((e) => {
                alert('confirm failed: ' + e.message);
            });
    }

    async function initData() {
        const resourceName = getQueryParam('resource');
        const version = getQueryParam('version');
        if (!resourceName || !version) {
            return;
        }

        setFileLoadingFlag(true);
        const dataResponse = await fetch(`/api/v1/resource/release/list?resource_name=${resourceName}&version=${version}`);
        const data = await dataResponse.json();

        if (data.err) {
            alert(data.err);
            return;
        }

        setReleaseList(data.data.releaseList);
        setContent(JSON.stringify(data.data.currentList, null, 2));
        setFileLoadingFlag(false);
    }

    async function changeRelease (queryRelease) {
        const resourceName = getQueryParam('resource');
        const version = getQueryParam('version');
        if (!resourceName || !version) {
            return;
        }

        RELEASE = queryRelease;
        setFileLoadingFlag(true);

        const dataResponse = await fetch(`/api/v1/resource/release/list?resource_name=${resourceName}&version=${version}&query_release=${queryRelease}`);
        const data = await dataResponse.json();

        if (data.err) {
            alert(data.err);
            return;
        }

        setContent(JSON.stringify(data.data.currentList, null, 2));
        setFileLoadingFlag(false);
    }

    useEffect(() => {
        initData('current.json');
    }, []);

    return (
        <div className="page-wrapper">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">个性化资源管理</h3>
                </div>
                <div className="col-md-7 align-self-center">
                </div>
            </div>
            <div className="container-fluid">
                <div className="card" id="card">
                    <div style={{marginBottom: 20}}>
                        <div id="submit" style={{display: 'inline-block'}}>
                            <Button type="primary" htmlType="submit" onClick={onConfirm}>
                                确认回滚
                            </Button>
                        </div>
                        <div id="release-select" style={{display: 'inline-block', float: 'right'}}>
                            <Select
                                style={{ width: 200 }}
                                placeholder="Select a version"
                                optionFilterProp="children"
                                defaultValue="current.json"
                                onSelect={changeRelease}
                            >
                                {
                                    (() => {
                                        return releaseList.map((k) => {
                                            return (<Select.Option value={k}>{k}</Select.Option>);
                                        })
                                    })()
                                }
                            </Select>
                        </div>
                    </div>
                    <div id="content">
                        <Card title="接口下发内容" bordered={true}>
                            <pre>{content}</pre>
                        </Card>
                    </div>
                    <div id="loading" style={{position: 'fixed', marginTop: 2, marginLeft: 300}}>
                        <Spin spinning={fileLoadingFlag} tip="Loading...">

                        </Spin>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ResourceRollback;

