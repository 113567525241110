import React, { useState, useRef, useEffect } from 'react';
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/selection/active-line';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import './DifferentSettingList.scss';
import {UnControlled as CodeMirror} from "react-codemirror2";
import { Tabs } from 'antd';
const { TabPane } = Tabs;

let CurrentPlan = 'planA';
let CurrentPlatform = '';

const DifferentSettingList = () => {
    const codeEditorRef = useRef();
    const userGroupEditorRef = useRef();
    const [tabPlans, setTabPlans] = useState({
        activeKey: 'planA',
        panes: [],
    });
    const [platformListData, setPlatformListData] = useState({});

    /**
     * 某一个平台，展示差异化配置，用户分组，以及开关
     * 并更新curKey
     * @param {*} platform
     */
    async function showDiffSetting(platform) {
        CurrentPlatform = platform;
        await showGroups(platform);
        await showConfig(platform);
    }

    async function showConfig(platform) {
        const editor = codeEditorRef.current.editor;
        const editor2 = userGroupEditorRef.current.editor;
        editor.setValue('');
        editor2.setValue('');
        $("#timing").empty();

        $(`${platform}`).addClass("clicked").siblings().removeClass("clicked");
        const different_setting = await queryDiffConfig(platform, CurrentPlan);
        const user_group = await queryDiffUserGroup(platform, CurrentPlan);
        const swt = await queryDiffSwitch(platform);

        const cronDiffConfig = await queryCronDiffSetting(platform);

        // 展示差异化配置
        let content = {};
        if (different_setting && typeof different_setting === "object") {
            for (const setting of different_setting) {
                const key = setting.platform_type.split("##")[1] + "." + setting.setting_name;
                content[key] = setting.setting_value;
            }
        }

        if (Object.keys(content).length > 0) {
            content = JSON.stringify(content, null, 4);
            editor.setValue(content);
        }

        // 展示用户分组
        // await showGroupDiff(user_group, platform);
        editor2.setValue(JSON.stringify(user_group.group_info), null, 4);

        // 展示差异化配置开关
        const switch_txt = swt === 'on' ? '开' : '关';
        $("#switch").html(switch_txt);
        if (cronDiffConfig && Object.keys(cronDiffConfig).length > 0) {
            // let cronHtml = '<div style="height: 40px; margin-bottom: 5px">定时器配置: </div>';
            let cronHtml = '';
            console.log(cronDiffConfig);
            const cronUserGroup = cronDiffConfig.groupInfo || '';
            const crontabRule = cronDiffConfig.crontabRule || {};
            cronHtml += '<div style="height: 40px; margin-bottom: 10px">' +
                '定时器用户分组: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                JSON.stringify(cronUserGroup, null, 4)+
                '</div>';
            if (Object.keys(crontabRule).length > 0) {
                const startConfig = crontabRule.start;
                const endConfig = crontabRule.end;
                let start_day_of_week = startConfig && startConfig.day;
                const start_hour_of_day = startConfig && startConfig.hour;
                let end_day_of_week = endConfig && endConfig.day;
                const end_hour_of_day = endConfig && endConfig.hour;
                const days = {
                    '1': '星期一',
                    '2': '星期二',
                    '3': '星期三',
                    '4': '星期四',
                    '5': '星期五',
                    '6': '星期六',
                    '7': '星期日',
                };
                cronHtml += '<div style="height: 60px; margin-bottom: 10px">' +
                    '<div>' +
                    '定时器开始时间: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + days[start_day_of_week.toString()] + '&nbsp;&nbsp;&nbsp;' + start_hour_of_day.toString() + '时' +
                    '</div>' +
                    '<div>' +
                    '定时器结束时间: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + days[end_day_of_week.toString()] + '&nbsp;&nbsp;&nbsp;' + end_hour_of_day.toString() + '时' +
                    '</div>' +
                    '</div>';
            }
            cronHtml += '<div style="height: 40px;">' +
                '定时器开关:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                `<span style=\"color: red\">${cronDiffConfig.crontabEnabled}</span>` +
                '</div>';
            $("#timing").html(cronHtml)
        }
    }

    async function showGroups (platform) {
        let username = localStorage.getItem("username");
        $.ajax({
            type: "GET",
            url: "/api/v1/config/get_diff_setting_user_groups/"+ "?username=" + username + "&menuId=setting" + `&platform=${platform}`,
            success: function (data) {
                if (!data || data.err) {
                    if (data.isReload) {
                        window.location.href = "/login.html";
                        return;
                    }
                    alert("提交失败：" + data.err);
                } else if (data.status === 200) {
                    const plans = data.groups.map((group) => {
                        return {title: `${group.plan_name}-${group.alias}`, key: group.plan_name};
                    });
                    setTabPlans({activeKey: CurrentPlan, panes: plans});
                } else {
                    alert("查询用户分组，网络出问题了，请稍后重试.");
                }
            },
            error: function (req, status, err) {
                alert("request failed.[err: " + err + "]  ಠ_ಠ");
            },
        });
    }

    /**
     * 获得某平台的某个差异化配置
     * 并且更新菜单
     * @param {*} platform
     * @param {*} settingName
     */
    async function queryDiffConfig(platform, settingName) {
        return new Promise(async (resolve, reject) => {
            const configType = 'special';
            if (!platform) {
                alert("缺少平台名");
                return;
            }

            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/config/single_config?platform=" + platform + "&config_type=" + configType + "&setting_name=" + settingName + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("Query Failed：" + data.err);
                        reject(data.err);
                    } else {
                        resolve(data.data.setting_value);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("查询配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                },
            });
        });
    }

    async function queryDiffUserGroup(platform, plan) {
        return new Promise(async (resolve, reject) => {
            if (!platform) {
                alert("缺少平台名");
                return;
            }

            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            $.ajax({
                type: "GET",
                url: "/api/v1/config/get_diff_setting_user_groups?platform=" + platform + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("查询用户分组失败:" + data.err);
                        reject(data.err);
                    } else {
                        let res;
                        for (const group of data.groups) {
                            if (group.plan_name === plan) {
                                res = group;
                                break;
                            }
                        }
                        resolve(res);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("查询用户分组失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                },
            });
        });
    }


    async function queryDiffSwitch(platform) {
        return new Promise(async (resolve, reject) => {
            if (!platform) {
                alert("缺少平台名");
                return;
            }

            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            $.ajax({
                type: "GET",
                url: "/api/v1/config/get_diff_setting_switch/"+ "?username=" + username + "&menuId=setting" + `&platform=${platform}`+ '&menuId=' + menuId,
                success: function (data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert('获取差异化配置开关失败');
                        reject(data.err)
                    } else if (data.status === 200) {
                        resolve(data.data.switch)
                    } else {
                        alert("request failed");
                        reject('failed')
                    }
                },
                error: function (req, status, err) {
                    const message = err.message || err;
                    alert("查询差异化配置开关，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(message)
                },
            });
        });
    }

    async function queryCronDiffSetting (platform) {
        return new Promise(async (resolve, reject) => {
            if (!platform) {
                alert("缺少平台名");
                return;
            }

            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            $.ajax({
                type: "GET",
                url: "/api/v1/cron_diff_config/single_config"+ "?username=" + username + "&menuId=setting" + `&plan=${CurrentPlan}` + `&platform=${platform}`+ '&menuId=' + menuId,
                success: function (data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert('获取差异化配置开关失败');
                        reject(data.err)
                    }
                    resolve(data.data)
                },
                error: function (req, status, err) {
                    const message = err.message || err;
                    alert("查询定时差异化配置，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(message)
                },
            });
        });
    }

    $("li").click(function() {
        $(this).addClass("clicked").siblings().removeClass("clicked");
    });

    async function initPlatform() {

        let username = localStorage.getItem("username");
        let menuId = $("#menu_id").html();
        $.ajax({
            type: "GET",
            url: "/api/v1/config/diff_platform" + '?username=' + username + '&menuId=' + menuId,
            success: function(data) {
                if (data.err) {
                    if (data.isReload) {
                        window.location.href = "./login.html";
                    } else {
                        alert(data.err);
                    }
                    return;
                }
                // buildMenu(data.data, "platformList");
                setPlatformListData(data.data);
                // if (data.data.length > 0) {
                //     $($("#platformList").children("li").get(0)).click().addClass("clicked").siblings().removeClass("clicked");
                // }
            },
            error: function(req, status, err) {
                const message = err.message || err;
                alert("查询失败，网络出问题了，请稍后重试。错误原因:" + message);
            },
        })
    }

    async function planTabChange(plan) {
        CurrentPlan = plan;
        const { panes } = tabPlans;
        setTabPlans({activeKey: plan, panes})
        await showDiffSetting(CurrentPlatform)
    }

    useEffect(async () => {
        $(".CodeMirror-scroll").hover(function() {
            $(this).get(0).style.cursor = "text";
        });
        codeEditorRef.current.editor.setSize('auto', 'auto');
        userGroupEditorRef.current.editor.setSize('auto', 'auto');

        await initPlatform();
    }, []);

    return (
        <div id="different-setting-list-component">
            <div className="page-wrapper" style={{overflowAnchor: 'none', marginTop: '20px'}}>
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-info" style={{display: 'inline-block'}}>
                            差异化配置列表
                        </h3>
                    </div>
                    <div className="col-md-7 align-self-center">
                    </div>
                </div>
                <div className="container-fluid" style={{overflowX: 'scroll'}}>
                    <div className="tab-content" style={{minWidth: 1296}}>
                        <div className="tab-pane active show" id="Pane" role="tabpanel" style={{overflowX: 'hidden', overflowY: 'hidden'}}>
                            <div id="change-col" className="col">
                                <div className="inbox-leftbar-w">
                                    <div id="newConfigItem" type="text" style={{height: 25, fontSize: 'medium', position: 'relative', textAlign: 'center'}}>
                                        平 台 列 表
                                    </div>
                                    <div className="mail-list mt-4" style={{borderRadius: '.9rem', background: 'white', position: 'relative', top: '-30px'}}>
                                        <div id="platformList" style={{position: 'relative', top: 50, textAlign: 'center', minHeight: 800}}>
                                            {(() => {
                                                return Object.keys(platformListData).map((item) => {
                                                    if (platformListData[item]) {
                                                        return (
                                                            <li
                                                                id={item}
                                                                key={item}
                                                                onClick={showDiffSetting.bind(this, item)}
                                                                style={{height: '35px'}}
                                                            >
                                                                <a href="#" style={{fontSize: 'small', color: 'black'}}>{item}
                                                                    &nbsp;&nbsp;
                                                                    <i className="fa fa-clock-o"/>
                                                                </a>
                                                            </li>
                                                        );
                                                    }
                                                    return (
                                                        <li
                                                            id={item}
                                                            key={item}
                                                            onClick={showDiffSetting.bind(this, item)}
                                                            style={{height: '35px'}}
                                                        >
                                                            <a href="#" style={{fontSize: 'small', color: 'black'}}>{item}</a>
                                                        </li>
                                                    );
                                                });
                                            })()}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: 20, minHeight: 830}}>
                                    <div className="card" id="single_diff_setting" style={{width: '100%', position: 'relative'}}>
                                        <div className="container-fluid">
                                            <Tabs
                                                type="card"
                                                onChange={planTabChange}
                                                activeKey={tabPlans.activeKey}
                                            >
                                                {tabPlans.panes.map(pane => {
                                                    if (pane.title === 'planA') {
                                                        return (<TabPane tab={pane.title} key={pane.key} closable={false}>
                                                        </TabPane>)
                                                    } else {
                                                        return (<TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
                                                        </TabPane>)
                                                    }
                                                })}
                                            </Tabs>
                                        </div>
                                        <div style={{height: 40}}>
                                            差异化配置:
                                        </div>
                                        <div>
                                            <CodeMirror
                                                id="code"
                                                className="config_content"
                                                ref={codeEditorRef}
                                                value=''
                                                options={{
                                                    mode: 'application/ld+json',
                                                    lineNumbers: true,
                                                    indentUnit: 4
                                                }}
                                                onChange={(editor, data, value) => {
                                                    //
                                                }}
                                            />
                                        </div>
                                        <hr />
                                        <div style={{height: 40}}>
                                            用户分组:
                                        </div>
                                        <div>
                                            <CodeMirror
                                                id="user_group"
                                                className="config_content"
                                                ref={userGroupEditorRef}
                                                value=''
                                                options={{
                                                    mode: 'application/ld+json',
                                                    lineNumbers: true,
                                                    indentUnit: 4
                                                }}
                                                onChange={(editor, data, value) => {
                                                    //
                                                }}
                                            />
                                        </div>
                                        <hr />
                                        <div>
                                            配置开关:
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span id="switch" style={{color: 'red'}} />
                                        </div>
                                        <hr />
                                        <div id="timing">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DifferentSettingList;
