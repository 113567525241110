import React, { Component } from 'react'
import { Table, Button, Space, Typography, Modal, Form, Input, message } from 'antd';
import axios from "axios";

class PlatFormToEmail extends Component {
    state = {
        list: [],
        modalVisible: false,
        layout: {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
        },
        limit: 10,
        initLoading: true,
        email: "",
        platform: "",
        type: 0 // 0 新增 --  1 编辑
    }

    formRef = React.createRef()

    componentDidMount () {
        this.getData();
    }

    getData = async (last) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.get("/api/v1/question/platform_email_list", {
                    params: {
                        limit: 10,
                        last
                    }
                });
                const list = last ? [...this.state.list, ...result.data.data] : result.data.data;
                this.setState({
                    initLoading: false,
                    list,
                    last: result.data.last
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    handleAdd = async (row) => {
        await this.setState({
            modalVisible: true,
            type: row ? 1 : 0
        })
        console.log("this.formRef", this.formRef);
        this.formRef.current.setFieldsValue({...row})
    }

    deleteRow = async (row) => {
        try {
            console.log(row.platform);
            const result = await axios.post("/api/v1/question/platform_email_del", {
                platform: row.platform
            });
            console.log("delete result", result);
            if (result && result.data && result.data.err) {
                message.error(result.data.err);
            } else {
                message.success("success");
                this.getData();
            }
        } catch (e) {
            message.error(e);
        }
    }

    handleOk = async () => {
        try {
            const {platform, email} = this.formRef.current.getFieldsValue(true);
            if (!platform) {
                message.error("请输入平台名！");
                return;
            }
            if (!email) {
                message.error("请输入邮箱！");
                return;
            }
            const url =
                this.state.type === 1 ?
                    "/api/v1/question/platform_email_edit" :
                    "/api/v1/question/platform_email_add"
            const result = await axios.post(url, {
                platform,
                email
            });
            if (result && result.data && result.data.err) {
                message.error(result.data.err);
            } else {
                message.success("success");
                this.handleCancel();
                this.getData();
            }
        } catch (e) {
            console.log(e, "ee");
            message.error(e);
        }
    }

    handleCancel = () => {
        this.formRef.current.resetFields();
        this.setState({
            modalVisible: false
        })
    }

    render () {
        const columns = [
            {
                title: 'Platform',
                dataIndex: 'platform',
                key: 'platform',
            },
            {
                title: 'Email Address',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Button type="link" onClick={() => this.handleAdd(record)}>edit</Button>
                        <Button type="link" onClick={() => this.deleteRow(record)}>delete</Button>
                    </Space>
                ),
            },
        ];

        return (
          <div id="question-list-component">
              <div className="page-wrapper" style={{overflowAnchor: 'none', marginTop: '20px'}}>
                  <div className="row page-titles">
                      <div className="col-md-5 align-self-center">
                          <h3 className="text-primary">用户反馈信息</h3>
                      </div>
                      <div className="col-md-7 align-self-center">
                      </div>
                  </div>
                  <div className="container-fluid">
                      <div className="card" id="card" style={{display: 'inline-box'}}>
                          <div style={{display: "flex", padding: "16px 16px 0"}}>
                              <Typography.Title style={{flex: 1}} level={3}>平台与邮箱映射关系表</Typography.Title>
                              <Button onClick={() => this.handleAdd()} type="primary" style={{ marginBottom: 16 }}>
                                  新建映射
                              </Button>
                          </div>
                          <Table
                            columns={columns}
                            dataSource={this.state.list}
                            rowKey={record => record.email + Math.random()}
                            pagination={false}
                          />
                          <Modal
                            title="新建映射"
                            visible={this.state.modalVisible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}>
                              <Form
                                ref={this.formRef}
                                {...this.state.layout}
                                name="nest-messages"
                              >
                                  <Form.Item name="platform" label="Platform">
                                      <Input
                                        value={this.state.platform}
                                        placeholder="Platform here"
                                      />
                                  </Form.Item>
                                  <Form.Item name="email" label="Email" >
                                      <Input
                                        value={this.state.email}
                                        placeholder="Email here"
                                      />
                                  </Form.Item>
                              </Form>
                          </Modal>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

export default PlatFormToEmail
