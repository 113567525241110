import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button } from 'antd';

import './Login.scss';

function onSubmit(e) {
    localStorage.clear();
    var username= $("#username").val();
    var password= $("#password").val();
    var phoneCode = $('#phone-code-input-text').val();
    var body={
        username:username,
        password:password,
        phone_code: phoneCode
    };
    let pageUrl_login = '';
    $.ajax({
        url: "/auth/login",
        type:"POST",
        data:body,
        success: function(data){
            if(data.err){
                alert(data.err);
            }else{
                localStorage.setItem("user_session_id",data.data.userSessionId);
                localStorage.setItem("username",username);
                localStorage.setItem("role",data.data.role);
                pageUrl_login = "/page/setting.html";
                window.location.href=pageUrl_login;
            }
        },
        error:function(err){
            alert("登录失败",err);

        }
    });
    e.preventDefault();
}

const Login = () => {
    const history = useHistory();
    const usernameRef = useRef();
    const passwordRef = useRef();
    const phoneCodeRef = useRef();
    const tipRef = useRef();
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [disableStatus, setDisableStatus] = useState(false);
    const [buttonText, setButtonText] = useState('发送验证码');

    async function onClick () {
        setLoadingStatus(true);
        try {
            const usernameItem = $("#username").val();
            const response = await fetch(`/auth/send_phone_code`, {
                method: 'post',
                body:    JSON.stringify({username: usernameItem.trim()}),
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            if (data.err) {
                alert(data.err);
            }
            const phone = data.data.phone;
            if (phone && typeof phone === 'string') {
                document.getElementById("phone-code-tip").innerHTML = `已向${phone}发送验证码, 若连续2次未收到验证码, 请联系后台管理员, 勿不断重试`;
            }
        } catch (e) {
            alert(e.message);
        }
        setLoadingStatus(false);
        setDisableStatus(true);

        let timeout = 60;
        const timeInterval = setInterval(() => {
            timeout--;
            setButtonText(`${timeout < 10 ? `0${timeout}` : timeout}s后再次发送`);
            if (timeout <= 0) {
                clearInterval(timeInterval);
                setDisableStatus(false);
                setButtonText('发送验证码');
            }
        }, 1000);
    }

    useEffect(() => {
        (async () => {
            const username = localStorage.getItem("username");
            const checkRes = await fetch(`/healthy?username=${username}`);
            const checkTxt = await checkRes.text();
            if (checkTxt === 'healthy') {
                history.push('/page/setting.html');
                return;
            }
        })()
    }, []);

    return (
        <div id="login-component">
            <div id="main-wrapper">
                <div className="unix-login">
                    <div className="row justify-content-center">
                        <div className="col-lg-4">
                            <div className="login-content card">
                                <div className="login-form">
                                    <h4>Login</h4>
                                    <form id="loginForm">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <Input ref={usernameRef} id="username" type="text" className="form-control" placeholder="Username"/>
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <Input ref={passwordRef} id="password" type="password" className="form-control" placeholder="Password"/>
                                        </div>
                                        <div className="form-group" id="phone-code">
                                            <div>
                                                <label>短信验证码</label>
                                            </div>
                                            <div id="phone-code-input">
                                                <Input ref={phoneCodeRef} id='phone-code-input-text' placeholder="6位验证码" size="large" autocomplete="off"/>
                                            </div>
                                            <div id="phone-code-button">
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    loading={loadingStatus}
                                                    disabled={disableStatus}
                                                    onClick={onClick}
                                                >
                                                    {buttonText}
                                                </Button>
                                            </div>
                                            <p ref={tipRef} id="phone-code-tip">&nbsp;<br/>&nbsp;</p>
                                        </div>
                                        <button type="submit" onClick={onSubmit} className="btn btn-primary btn-flat m-b-30 m-t-30">Login</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
