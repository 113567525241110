import styled from 'styled-components';

export const FilterWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 24px 18px;
  border: 1px solid #eaeaea;
  margin-bottom: 20px;
`;

export const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .input-search {
    margin-right: 20px;
    display: block;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .input-search {
    margin-right: 20px;
    display: block;
  }
`;

export const SectionTitle = styled.div`
  font-size: 12px;
  margin: 0;
  padding-right: 20px;
`;

export const PlatFormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
