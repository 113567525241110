import React, { useState, useRef } from 'react';

const Header = () => {
    return (
        <div className="header">
            <nav className="navbar top-navbar navbar-expand-md navbar-light">
					<span>
						<img src="images/logo-2x.png" alt="homepage" className="dark-logo" style={{width: '90px', position: 'relative', left: '20px'}}/>
					</span>
                <div className="navbar-header">
                    <a className="navbar-brand" href="/setting.html">
                    </a>
                </div>
            </nav>
        </div>
    );
};

export default Header;
