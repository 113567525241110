import React, { useState, useEffect, useRef } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/selection/active-line';

import { Input, AutoComplete, Button } from 'antd';
import ReactDiffViewer from 'react-diff-viewer';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import './ThirdLoginSetting.scss';

let tolConfig = {};
let curType = "client";
let curKey = "";
let database_config_content = "";
let Platform1 = "";
let Platform2 = "";
let diffConfigType = "client";
let allPlatformList = [];
let allIapCheckPlatformList = [];


const ThirdLoginSetting = () => {
    const codeEditorRef = useRef();

    const [configListData, setConfigListData] = useState([]);
    const [platformAutoComplete, setPlatformAutoComplete] = useState([]);

    const [twoCofnigList, setTwoCofnigList] = useState([]);

    const [platform1ConfigValue, setPlatform1ConfigValue] = useState('');
    const [platform2ConfigValue, setPlatform2ConfigValue] = useState('');
    const [splitView, setSplitView] = useState(true);

    const [preConfigValue, setPreConfigValue] = useState('');
    const [currentConfigValue, setCurrentConfigValue] = useState('');
    const [splitView1, setSplitView1] = useState(true);

    /**
     * 点击菜单中的某个key，展现某一条key的value
     * 并更新curKey
     * @param {*} key
     */
    async function showConfigItem(key, codeEditorRef) {
        const editor = codeEditorRef.current.editor;
        editor.setValue('');
        $("#" + key).addClass("clicked").siblings().removeClass("clicked");
        $("#config_item_lable").empty();
        $("#config_item_lable").html("Start get config ...");
        curKey = key;
        await querySingle(curKey);
        $("#config_item_lable").html(curKey);
        let content = tolConfig[key];
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }
        content = formatJson(content);
        database_config_content = content;
        editor.setValue(content);
    }

    /**
     * 点击保存时，保存输入框中内容
     */
    async function save() {
        const editor = codeEditorRef.current.editor;
        let configValue = editor.getValue();
        if (!curKey || !configValue) {
            alert("缺少参数");
            return;
        }
        let r = confirm("确认输入内容无误，继续保存吗？");
        if (!r) { return; }

        configValue = formatJson(configValue);

        try {
            configValue = JSON.parse(configValue);
        } catch (e) {
            configValue = configValue.trim();
        }
        if (typeof(configValue) == "object") {
            configValue = JSON.stringify(configValue, null, 4);
        }

        updateConfig(curKey, configValue);
    }

    /**
     * 点击格式化时，将输入框中的json格式化
     */
    async function format() {
        const editor = codeEditorRef.current.editor;
        let content = editor.getValue();
        let result = formatJson(content);

        if (result) {
            editor.setValue(result);
        }
    }
/////////////////////////////////////////////////////////////////////////////////////
// 网络请求函数
/////////////////////////////////////////////////////////////////////////////////////
    /**
     * 获得所有配置
     * 并且更新菜单
     */
    async function queryAll() {
        return new Promise(async (resolve, reject) => {
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/third_login_config/list" + '?username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("Query Failed：" + data.err);
                        return;
                    } else {
                        let allKey = [];
                        for (const item of data.data) {
                            allKey.push(item)
                        }
                        resolve(allKey);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("网络出问题啦(查询配置失败)，请稍后重试。错误原因:" + message);
                }
            });
        });
    }

    /**
     * 获得单个配置
     * 并且更新菜单
     * @param {*} settingName
     */
    async function querySingle(settingName) {
        return new Promise(async (resolve, reject) => {
            if (!settingName) {
                alert("未选中配置");
                return;
            }
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/third_login_config/detail" + "?setting_name=" + settingName + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("Query Failed：" + data.err);
                        reject(data.err);
                    } else {
                        tolConfig[settingName] = data.data;
                        resolve();
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("网络出问题啦(查询配置失败)，请稍后重试。错误原因:" + message);
                },
            });
        });
    }

    /**
     * 更新config的内容
     * @param {*} configKey
     * @param {*} configValue
     */
    async function updateConfig(configKey, configValue) {
        return new Promise(async (resolve, reject) => {
            let username = localStorage.getItem("username");
            $.ajax({
                type: "POST",
                url: "/api/v1/third_login_config/update?username=" + username + "&menuId=setting",
                data: {
                    setting_name: configKey,
                    setting_value: configValue,
                },
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("提交失败：" + data.err);
                        reject(data.err);
                    } else {
                        alert("Change Success.");
                        resolve();
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("更新配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                },
            });
        });
    }

    function onSearchPlatformAutoComplete (txt) {
        const filterPlatforms = allPlatformList.filter((platform) => {
            return platform.toLowerCase().indexOf(txt.toLowerCase()) > -1;
        });
        setPlatformAutoComplete(filterPlatforms.map((platform) => {
            return {value: platform};
        }));
    }
    /////////////////////////////////////////////////////////////////////////////////////
// controller函数
/////////////////////////////////////////////////////////////////////////////////////

    /**
     * 输入一个数组和需要加入列表的id，直接生成菜单栏
     * @param {*} list
     * @param {*} id
     */
    async function buildMenu(list, id) {
        $("#" + id).empty();
        let len = list.length;
        let html = "";
        for (let i = 0; i < len; i++) {
            let key = list[i];
            html = '<li id="' + key + '" onclick="showConfigItem(\'' + key + '\')" style="height: 35px"><a href="#" style="font-size: small;color: black;">' + key + "</a></li>";
            $("#" + id).append(html);
        }
    }

    /**
     * 格式化JSON
     */
    function formatJson(content) {
        if (!content) {
            return "";
        }
        try {
            content = JSON.parse(content);
        } catch (e) {
            content = content.replace(/\s/g, "");
            try {
                const objJsonParsed = content.includes("{") && content.includes("}");
                const arrayJsonParsed = content.includes("[") && content.includes("]");
                const numberJsonParsed = content.match(new RegExp(/^[0-9]*$/));
                if (objJsonParsed || arrayJsonParsed || numberJsonParsed) {
                    content = JSON.parse(content);
                }
            } catch (e) {
                alert(e);
                return;
            }
        }
        if (typeof(content) == "object") {
            content = JSON.stringify(content, null, 4);
        }
        if (typeof (content) === "number") {
            content = content.toString();
        }
        return content;
    }

    function diffUsingJS(old_content, editorRef, display_diff_div_obj, view_type) {
        setPreConfigValue(old_content);
        setCurrentConfigValue(editorRef.current.editor.getValue());
        setSplitView1(!view_type);
    }

    $("li").click(function() {
        $(this).addClass("clicked").siblings().removeClass("clicked");
    });

    $("#btn_check").click(function() {
        // alert('格式正确');
        diffUsingJS(database_config_content, codeEditorRef, $("#diffoutput"), 0);
        $("#text_diff_modal").css({
            "width": "100%",
            // "margin-left": "-50%",
            "height": "95%",
            "margin-top": "-70px",
            "background-color": "white",
            "top": "90px",
            "position": "fixed",
            "overflow-x": "auto",
        });
        $("h3").css({
            "position": "relative",
            "left": "-30%",
        });
        $("#text_diff_modal div.modal-body").css({
            "max-height": "100%",
            "padding-bottom": "20px",
        });
        $("#diffoutput").css({
            "max-height": window.screen.availHeight - 120 + "px",
        });
        $("#text_diff_modal").modal();
    });

    /**
     * 当两个平台名都有值时，默认获取client的所有配置项名，取并集
     * @returns {Promise<void>}
     */
    async function checkInputValue() {
        const platform1 = $('#first_platform_input').val();
        const platform2 = $('#second_platform_input').val();
        // console.log(platform1, platform2);
        if (platform1 && platform2) {
            Platform1 = platform1; // 更改变量值，留作其他地方使用
            Platform2 = platform2;
            await buildDiffMenu(Platform1, Platform2)
        }
    }

    /**
     * 根据两个平台名生成左侧配置栏
     * @param {*} platform1
     * @param {*} platform2
     */
    async function buildDiffMenu(platform1, platform2) {
        const platform1Config = await queryAllConfig(platform1, diffConfigType);
        const platform2Config = await queryAllConfig(platform2, diffConfigType);
        // console.log(platform1Config);
        // console.log(platform2Config);
        const list = Array.from(new Set(platform1Config.concat(platform2Config)));
        setTwoCofnigList(list);
        // $("#twoConfigList").empty();
        // let len = list.length;
        // let html = "";
        // for (let i = 0; i < len; i++) {
        //     let key = list[i];
        //     html = '<li id="' + key + '" onclick="showConfigDiff(\'' + key + '\')" style="height: 35px"><a href="###" style="font-size: small;color: black;">' + key + "</a></li>";
        //     $("#twoConfigList").append(html);
        // }
    }

    async function showConfigDiff (key) {
        // $("#two_platform_config_diff").empty();
        setPlatform1ConfigValue('');
        setPlatform2ConfigValue('');
        let platform1ConfigValue = await querySingleConfig(Platform1, diffConfigType, key);
        let platform2ConfigValue = await querySingleConfig(Platform2, diffConfigType, key);
        platform2ConfigValue = platform2ConfigValue ? JSON.stringify(platform2ConfigValue, null, 2) : '';
        platform1ConfigValue = platform1ConfigValue ? JSON.stringify(platform1ConfigValue, null, 2) : '';
        diffTwoConfigUsingJS(platform1ConfigValue, platform2ConfigValue, $("#two_platform_config_diff"), 0);
        $("#" + key).addClass("lactive").siblings().removeClass("lactive");
    }

    async function diffTabChange (configType) {
        setTwoCofnigList([]);
        setPlatform1ConfigValue('');
        setPlatform2ConfigValue('');
        diffConfigType = configType;
        await buildDiffMenu(Platform1, Platform2);
    }

    /**
     * 获得某平台的某个configtype下的所有配置
     * @param {*} platform
     * @param {*} configType
     */
    async function queryAllConfig(platform, configType) {
        return new Promise(async (resolve, reject) => {
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/config/content?platform=" + platform + "&config_type=" + configType + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        alert("Query Failed：" + data.err);
                        return;
                    } else {
                        // 获得所有key组成的数组
                        let allKey = Object.keys(data.data[configType]);
                        resolve(allKey);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("查询配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                },
            });
        });
    }

    /**
     * 获得某平台的某个configtype下的单个配置
     * @param {*} platform
     * @param {*} configType
     * @param {*} settingName
     */
    async function querySingleConfig(platform, configType, settingName) {
        return new Promise(async (resolve, reject) => {
            let username = localStorage.getItem("username");
            let menuId = $("#menu_id").html();
            // 获得所有config
            $.ajax({
                type: "GET",
                url: "/api/v1/config/single_config?platform=" + platform + "&config_type=" + configType + "&setting_name=" + settingName + '&username=' + username + '&menuId=' + menuId,
                success: function(data) {
                    if (!data || data.err) {
                        if (data.isReload) {
                            window.location.href = "/login.html";
                            return;
                        }
                        if (data === undefined) {
                            resolve({})
                        }
                        alert("Query Failed：" + data.err);
                        reject(data.err);
                    } else {
                        resolve(data.data.setting_value);
                    }
                },
                error: function(req, status, err) {
                    const message = err.message || err;
                    alert("查询配置失败，网络出问题了，请稍后重试。错误原因:" + message);
                    reject(err);
                },
            });
        });
    }

    function diffTwoConfigUsingJS(old_content, new_content) {
        setPlatform1ConfigValue(old_content);
        setPlatform2ConfigValue(new_content);
    }

    async function refresh() {
        const editor = codeEditorRef.current.editor;
        editor.setValue('');
        let allKey = await queryAll();
        setConfigListData(allKey);
        if (curKey) {
            await showConfigItem(curKey, codeEditorRef);
        }
    }

    useEffect(() => {
        $(".CodeMirror-scroll").hover(function() {
            $(this).get(0).style.cursor = "text";
        });
        codeEditorRef.current.editor.setSize('auto', 'auto');

        refresh();
    }, []);

    return (
        <div id="third-login-setting-component">
            <div className="page-wrapper" style={{overflowAnchor: 'none', marginTop: '20px'}}>
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-info" style={{display: 'inline-block'}}>
                            第三方登录校验 配置
                        </h3>
                    </div>
                    <div className="col-md-7 align-self-center">
                    </div>
                </div>
                <div className="container-fluid" style={{overflowX: 'scroll'}}>
                    <ul className="nav nav-tabs customtab2" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active show" data-toggle="tab" href="#Pane" role="tab"
                               aria-selected="true"
                            >
                                <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                  配置列表
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" style={{minWidth: '1296px'}}>
                        <div className="tab-pane active show" id="Pane" role="tabpanel"
                             style={{overflowX: 'hidden', overflowY: 'hidden'}}>
                            <div id="change-col" className="col">
                                <div className="inbox-leftbar" style={{marginTop: '24px'}}>
                                    <div className="mail-list mt-4" style={{
                                        borderRadius: '.9rem',
                                        background: 'white',
                                        position: 'relative',
                                        top: '-10px'
                                    }}>
                                        <div id="configList" style={{
                                            position: 'relative',
                                            top: '50px',
                                            textAlign: 'center',
                                            minHeight: '300px'
                                        }}>
                                            {(() => {
                                                return configListData.map((item) => {
                                                    return (
                                                        <li
                                                            id={item.setting_name}
                                                            key={item.setting_name}
                                                            onClick={showConfigItem.bind(this, item.setting_name, codeEditorRef)}
                                                            style={{height: '35px'}}
                                                        >
                                                            <a href="#" style={{fontSize: 'small',color: 'black'}}>{item.title}</a>
                                                        </li>
                                                    )
                                                });
                                            })()}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: '20px'}}>
                                    <div className="card" style={{width: '100%', height: '80px', position: 'relative'}}>
                                        <div className="row">
                                            <p id="write_status" style={{
                                                position: 'relative',
                                                top: '10px',
                                                left: '50px',
                                                fontSize: 'small'
                                            }}>
                                                Config Item:
                                                <span id="config_item_lable">
                        </span>
                                            </p>
                                            <button id="btn_refresh" className="btn btn-info btn-flat m-b-10 "
                                                    type="button" style={{
                                                width: '40px',
                                                height: '40px',
                                                position: 'absolute',
                                                left: '550px'
                                            }} onClick={refresh.bind(this)}>
                                                <i className="fa fa-refresh">
                                                </i>
                                            </button>
                                            <button id="btn_submit" className="btn btn-info btn-flat m-b-10 "
                                                    type="button" style={{
                                                width: '40px',
                                                height: '40px',
                                                position: 'absolute',
                                                left: '600px'
                                            }} onClick={save.bind(this)}>
                                                <i className="fa fa-save">
                                                </i>
                                            </button>
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: '70px',
                                                    height: '40px',
                                                    position: 'absolute',
                                                    left: '650px'
                                                }}
                                                onClick={format}
                                            >
                                                格式化
                                            </Button>
                                            <Button
                                                type="primary"
                                                id="btn_check"
                                                style={{
                                                    width: '60px',
                                                    height: '40px',
                                                    position: 'absolute',
                                                    left: '730px'
                                                }}
                                            >
                                                校验
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{width: '70%', marginLeft: '20px'}}>
                                    <div className="card" style={{width: '100%', position: 'relative'}}>
                                        <div>
                                            <CodeMirror
                                                id="code"
                                                className="config_content"
                                                ref={codeEditorRef}
                                                value=''
                                                options={{
                                                    mode: 'application/ld+json',
                                                    lineNumbers: true,
                                                    indentUnit: 4
                                                }}
                                                onChange={(editor, data, value) => {
                                                    //
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-20" id="op_diff_two_platform" role="tabpanel">
                            <div id="config-col" className="col">
                                <div className="inbox-leftbar" style={{width: '22%'}}>
                                    <ul className="nav nav-tabs customtab2" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active show" data-toggle="tab" role="tab"
                                               aria-selected="true" onClick={diffTabChange.bind(this, 'client')}>
                                                    <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                                      Client
                                                    </span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link show" data-toggle="tab" role="tab"
                                               aria-selected="true" onClick={diffTabChange.bind(this, 'common')}>
                                                    <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                                      Common
                                                    </span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link show" data-toggle="tab" role="tab"
                                               aria-selected="true" onClick={diffTabChange.bind(this, 'server')}>
                                                    <span className="hidden-xs-down" style={{fontSize: 'small'}}>
                                                      Server
                                                    </span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="mail-list mt-4" style={{
                                        borderRadius: '.9rem',
                                        background: 'white',
                                        position: 'relative',
                                        top: '-18px'
                                    }}>
                                        <div id="twoConfigList"
                                             style={{position: 'relative', textAlign: 'center', minHeight: '400px'}}>
                                            {(() => {
                                                return twoCofnigList.map((key) => {
                                                    return (
                                                        <li
                                                            id={key}
                                                            onClick={showConfigDiff.bind(this, key)}
                                                            style={{height: '35px'}}>
                                                            <a style={{fontSize: 'small', color: 'black'}}>
                                                                {key}
                                                            </a>
                                                        </li>
                                                    );
                                                });
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xlg-8 inbox-rightbar" style={{marginLeft: '30%', minHeight: '400px'}}>
                                <div className="col">
                                    {/*<input id="first_platform_input" type="text" className="form-control input-rounded"*/}
                                           {/*placeholder="platform 1" style={{*/}
                                        {/*height: '30px',*/}
                                        {/*width: '200px',*/}
                                        {/*fontSize: 'small',*/}
                                        {/*position: 'relative',*/}
                                        {/*left: '-15%'*/}
                                    {/*}} onChange={checkInputValue.bind(this)}/>*/}
                                    <AutoComplete
                                        options={platformAutoComplete}
                                        style={{
                                            width: 200,
                                            marginLeft: '-120px',
                                            marginRight: '50px'
                                        }}
                                        id="first_platform_input"
                                        onSearch={onSearchPlatformAutoComplete}
                                        placeholder="platform 1"
                                    />
                                    {/*<input id="second_platform_input" type="text" className="form-control input-rounded"*/}
                                           {/*placeholder="platform 2" style={{*/}
                                        {/*height: '30px',*/}
                                        {/*width: '200px',*/}
                                        {/*fontSize: 'small',*/}
                                        {/*position: 'relative',*/}
                                        {/*left: '-5%'*/}
                                    {/*}} onChange={checkInputValue.bind(this)}/>*/}
                                    <AutoComplete
                                        options={platformAutoComplete}
                                        style={{
                                            width: 200,
                                        }}
                                        id="second_platform_input"
                                        onSearch={onSearchPlatformAutoComplete}
                                        placeholder="platform 2"
                                    />
                                    <button
                                        id="btn_refresh"
                                        className="btn btn-info btn-flat m-b-10 "
                                        type="button"
                                        style={{
                                        width: '40px',
                                        height: '40px',
                                        position: 'absolute',
                                        left: '400px'
                                    }} onClick={checkInputValue.bind(this)}>
                                        <i className="fa fa-refresh">
                                        </i>
                                    </button>
                                    <Button type="primary" style={{marginLeft: '150px', height: '40px'}} onClick={() => {setSplitView(!splitView)}}>
                                        切换显示模式
                                    </Button>
                                </div>
                                <div className="card" style={{
                                    width: '100%',
                                    minHeight: '380px',
                                    position: 'relative',
                                    left: '-13%',
                                    overflowX: 'auto'
                                }}>
                                    <div id="two_platform_config_diff">
                                        <ReactDiffViewer
                                            oldValue={platform1ConfigValue}
                                            newValue={platform2ConfigValue}
                                            splitView={splitView}
                                            leftTitle={Platform1}
                                            rightTitle={Platform2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="text_diff_modal" className="modal hide">
                <div className="modal-header">
                    <a>
                    </a>
                    <h3>
                        文本比较
                        <button type="button" className="btn btn-danger" style={{float:'right', position: 'relative', left:'80%'}}
                                onClick={() => {
                                    $('#text_diff_modal').modal('toggle');
                                    $('#comfirm_btn').remove();
                                }}
                        >
                            关闭
                        </button>
                        <button type="button" className="btn btn-default"
                                style={{float:'right', position: 'relative', left:'75%', color: '#000'}}
                                onClick={() => {setSplitView1(false)}}>
                            在一栏中比较
                        </button>
                        <button type="button" id="last" className="btn btn-default"
                                style={{float:'right', position: 'relative', left: '70%', color: '#000'}}
                                onClick={() => {setSplitView1(true)}}>
                            分二栏比较
                        </button>
                    </h3>
                </div>
                <div className="modal-body">
                    <div id="diffoutput">
                        <ReactDiffViewer
                            oldValue={preConfigValue}
                            newValue={currentConfigValue}
                            splitView={splitView1}
                            leftTitle="修改前"
                            rightTitle="修改后"
                        />
                    </div>
                </div>
            </div>
            <div id="copy_diff_modal" className="modal hide">
                <div className="modal-header">
                    <a>
                    </a>
                    <h3>
                        <select id="diff_config" className="form-control custom-select" style={{width: "45%"}}>
                            <option value="">
                                请选择Config
                            </option>
                        </select>
                        <select id="diff_platform" className="form-control custom-select" style={{width: "45%"}}>
                            <option value="">
                                请选择Platform
                            </option>
                        </select>
                        <button type="button" className="btn btn-danger" style={{float: 'right', position: 'relative', left: '80%'}}
                                onClick={() => {$('#copy_diff_modal').modal('toggle');}}>
                            关闭
                        </button>
                        <button type="button" onClick={() => {}} className="btn btn-info"
                                style={{float:'right', position: 'relative', left:'65%'}}>
                            确认保存
                        </button>
                    </h3>
                </div>
                <div className="modal-body">
                    <div id="diffoutput_copy">
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThirdLoginSetting;
