import React, { useEffect } from 'react';
import {BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import LeftSidebarNew from './components/LeftSidebarNew';

import Login from './components/login/Login';
import Setting from './components/setting/Setting';
import s3 from './components/s3/s3';
import Dashuaibi from './components/dashuaibi/Dashuaibi';
import Review from './components/review/Review';
import DifferentSetting from './components/different_setting/DifferentSetting';
import DifferentSettingList from './components/different_setting_list/DifferentSettingList';
import CrontabDifferentSetting from './components/crontab_special_config/CrontabDifferentSetting'
import Resource from './components/resource/Resource';
import ResourcePreview from './components/resource/ResourcePreview';
import ResourceRollback from './components/resource/ResourceRollback';
import QuestionList from './components/question/questionList';
import PlatformToEmail from './components/question/platformToEmail';
import NovelSetting from './components/novel_setting/NovelSetting';
import ThirdLoginSetting from './components/third_login_setting/ThirdLoginSetting';
import SpecialSetting from "./components/special_setting/SpecialSetting";

const IRoute = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={IndexRoute}/>
                <Route path="/login.html" component={Login} />
                <Route path="/page" component={PageRoute} />
            </Switch>
        </BrowserRouter>
    );
};


const IndexRoute = () => {
    return (<Redirect to={'/page/setting.html'}/>);
};

const PageRoute = () => {
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const username = localStorage.getItem("username");
            const checkRes = await fetch(`/healthy?username=${username}`);
            const checkTxt = await checkRes.text();
            if (checkTxt !== 'healthy') {
                history.push('/login.html');
                return;
            }
        })()
    }, []);
    return (
        <div id="main-wrapper">
            <Header/>
            <LeftSidebarNew/>
            <div>
                <Switch>
                    <Route path="/page/setting.html" component={Setting} />
                    <Route path="/page/novel_setting.html" component={NovelSetting} />
                    <Route path="/page/third_login_setting.html" component={ThirdLoginSetting} />
                    <Route path="/page/zhimingisdashuaibi.html" component={Dashuaibi} />
                    <Route path="/page/review.html" component={Review} />
                    <Route path="/page/different_setting.html" component={DifferentSetting} />
                    <Route path="/page/different_setting_list.html" component={DifferentSettingList} />
                    <Route path="/page/crontab_different_setting.html" component={CrontabDifferentSetting} />
                    <Route path="/page/resource.html" component={Resource} />
                    <Route path="/page/resource_preview.html" component={ResourcePreview} />
                    <Route path="/page/resource_rollback.html" component={ResourceRollback} />
                    <Route path="/page/question_list.html" component={QuestionList} />
                    <Route path="/page/question_platform_to_email.html" component={PlatformToEmail} />
                    <Route path="/page/s3.html" component={s3} />
                </Switch>
            </div>
            <Footer/>
        </div>
    );
};


export default IRoute;
